import useGetColors from "../../utils/colors";

export const useGetTextBoxStyles = ({
  variant,
  color,
  error,
  isTouched,
  fieldValue,
  startAdornment,
  showStartAdornmentOnFocus,
  type,
  fullWidth,
}) => {
  const { textFieldColors } = useGetColors();
  const isOutline = variant === "outline";
  const isPasswordType = type === "password";

  let underLineInactiveColor = textFieldColors.primaryInactiveColor;
  let underLineActiveColor = textFieldColors.primaryActiveColor;
  let fontColor = underLineActiveColor;
  let labelColor = underLineActiveColor;
  let passwordFieldValueFontColor =
    textFieldColors.passwordTypePrimaryFontColor;

  if (color === "error" || error) {
    underLineInactiveColor = textFieldColors.errorInactiveColor;
    underLineActiveColor = textFieldColors.errorActiveColor;
    fontColor = textFieldColors.defaultFontColor;
    labelColor = underLineActiveColor;
    passwordFieldValueFontColor = textFieldColors.defaultFontColor;
  } else if (color === "secondary") {
    underLineInactiveColor = textFieldColors.secondaryInactiveColor;
    underLineActiveColor = textFieldColors.secondaryActiveColor;
    fontColor = underLineActiveColor;
    labelColor = underLineActiveColor;
    passwordFieldValueFontColor =
      textFieldColors.passwordTypeSecondaryFontColor;
  } else if (color !== "primary") {
    underLineInactiveColor = color;
    underLineActiveColor = color;
    fontColor = textFieldColors.defaultFontColor;
    labelColor = color;
    passwordFieldValueFontColor = color;
  }

  let shouldApplyLabelTransition = isTouched || fieldValue;
  if (startAdornment && !showStartAdornmentOnFocus) {
    shouldApplyLabelTransition = true;
  }

  return {
    textBoxContainer: {
      marginBottom: isOutline ? "0" : "2.5rem",
      width: "100%",
      minWidth: 125,
      maxWidth: fullWidth ? "100%" : 300,
    },
    textFieldWrapper: {
      position: "relative",
      fontSize: "1rem",
      paddingBottom: 6,
      width: "100%",
      borderBottom: `thin solid ${underLineInactiveColor}`,
      color: fontColor,
      ...(isOutline
        ? {
            padding: "8px 12px",
            border: 0,
            borderRadius: 4,
          }
        : {}),
    },
    textFieldLabel: {
      position: "absolute",
      fontSize: shouldApplyLabelTransition ? "80%" : "1rem",
      zIndex: 0,
      transform: `translateY(${shouldApplyLabelTransition ? "-18px" : "5px"})`,
      transition: "font-size 0.2s, transform 0.2s",
      cursor: "text",
      color: labelColor,
      ...(isOutline
        ? {
            padding: "0 5px",
          }
        : {}),
    },
    textFieldAdornmentWrapper: {
      display: "flex",
      gap: 10,
      justifyContent: "space-between",
      alignItems: "center",
    },
    textField: {
      backgroundColor: "transparent",
      border: 0,
      padding: isOutline ? 5 : "5px 0",
      fontSize: "1rem",
      zIndex: 1,
      flex: 1,
      outline: 0,
      width: "100%",
      color: fontColor,
      ...(isPasswordType
        ? {
            color: passwordFieldValueFontColor,
            letterSpacing: 6,
          }
        : {}),
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        margin: 0,
      },
    },
    errorMsg: {
      marginTop: "0.5rem",
      display: "block",
      fontSize: "0.9rem",
      color: labelColor,
    },
    underLine: {
      marginTop: 6,
      width: "100%",
      height: 2,
      backgroundColor: underLineActiveColor,
      transformOrigin: "center top",
      transform: "scale(0, 1)",
      position: "absolute",
      bottom: -1,
      left: 0,
      transition: "background-color 0.2s, transform 0.2s",
      ...(isTouched
        ? {
            transform: "scale(1, 1)",
          }
        : {}),
    },
    fieldSet: {
      pointerEvents: "none",
      position: "absolute",
      inset: "-8px -1px -2px",
      borderRadius: 4,
      border: `${isTouched ? "2px" : "1px"} solid ${
        isTouched ? underLineActiveColor : underLineInactiveColor
      }`,
      margin: 0,
      padding: "inherit",
    },
    legend: {
      opacity: 0,
      visibility: "hidden",
      padding: shouldApplyLabelTransition ? "0 6px" : 0,
      maxWidth: shouldApplyLabelTransition ? "unset" : "0.01px",
      fontSize: "80%",
      display: "block",
      float: "unset",
      whiteSpace: "nowrap",
      width: "auto",
      margin: 0,
      lineHeight: "unset",
    },
    phoneInput: {
      focus : {
        outline : 'none'
      }
    }
  };
};


// .react-tel-input .flag-dropdown {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   padding: 0;
//   border-radius: 3px 0 0 3px;


  // .react-tel-input .form-control {
  //   font-size: 14px;
  //   letter-spacing: .01rem;
  //   margin-top: 0 !important;
  //   margin-bottom: 0 !important;
  //   padding-left: 48px;
  //   background: #FFFFFF;
  //   border: none;
  //   border-radius: 5px;
  //   line-height: 25px;