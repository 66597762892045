import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useSubCategory = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setSubCatPayload] = useState(null);
  const [paramBrandid, setSubParam] = useState('');
  const [paramCatid, setCatParam] = useState('');
  console.log(userDetails, "userDetails")

  useEffect(() => {
    setIsLoading(true);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-auth-token", userDetails?.token);

    const params = JSON.stringify(payload);

    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    let url = '';
    if (paramCatid !== '' && paramBrandid !== '') {
      url = `${baseUrl}/sub_category/lists?user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&category_uuid=${paramCatid}&brand_uuid=${paramBrandid}&${payload}`
    }
    else if (paramCatid !== '') {
      url = `${baseUrl}/sub_category/lists?user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&category_uuid=${paramCatid}&${payload}`
    }
    else if (paramBrandid !== '') {
      url = `${baseUrl}/sub_category/lists?user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&brand_uuid=${paramBrandid}&${payload}`
    }
    else {
      url = `${baseUrl}/sub_category/lists?user_uuid=${userDetails?.uuid === undefined ? "" : userDetails?.uuid}&${payload}`
    }

    fetch(`${url}`, requestOptions)
      .then(async (response) => {
        const res = await response.text();
        if (response.status !== 200) {
          throw res;
        }
        return JSON.parse(res);
      })
      .then((result) => {
        result.sort((a, b) => {
          return a.sort_order - b.sort_order;
        });
        setData([...result]);
        setError(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
      
  }, [payload, paramBrandid, paramCatid]);

  return {
    subCategoryList: data,
    isCatLoading: isLoading,
    catError: error,
    setSubCatPayload,
    setSubParam,
    setCatParam,
  };
};
