import React, { useState, useEffect } from 'react';
import Rewards from './WebRewards';
import MobileRewards from './MobileRewards';

const ResponsiveScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); // Check the screen size on initial load
    window.addEventListener('resize', handleResize); // Add resize event listener
    return () => window.removeEventListener('resize', handleResize); // Cleanup the event listener on component unmount
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className="mobileScreen">
          <MobileRewards />
        </div>
      ) : (
        <div className="webScreen">
          <Rewards />
        </div>
      )}
    </div>
  );
};

export default ResponsiveScreen;
