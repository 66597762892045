/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import { createRef, useRef, useState, useContext } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { Row, Col, Dropdown, Form, Card, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import uuid from "react-uuid";
import Button from "../../components/Button";
import SignInTemplate from "../../templates/SignIn";
import { useGetVerificationStyles } from "./index.styles";
import { useVerification } from "../../hooks/services/useVerification";
import { UserContext } from "../../context/UserContext";
import { useEffect } from "react";
import { Snackbar as MuiSnackbar, ThemeProvider } from "@mui/material";
import Alert from '@mui/material/Alert';
import { theme } from "../../components/Snackbar/index.styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import CsLineIcons from 'cs-line-icons/CsLineIcons';

import appLogo from "../../assets/images/svg/appLogo.svg";

const Verification = () => {
  const classes = useGetVerificationStyles();
  const navigate = useNavigate();
  const { isOTPVerified, setOTPPayload, message, error } = useVerification();
  const { userDetails } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [openpopup, setOpenPopup] = useState(false);
  const [mobileotp, setmobileotp] = useState('')

  const handleClose1 = () => {
    setOpenPopup(false);
  };

  const [check, setCheck] = useState(false)

  const change = () => {
    if (check === false) {
      setCheck(true)
    }
    else {
      setCheck(false)
    }
  }

  const [otpValues, setOtpValues] = useState(['', '', '', '', '', '', '', '']);

  const otpFieldsRefs = useRef([]);

  const otpOnChangeHandler = (e, index) => {
    const val = e.target.value;
    if (/[0-9a-zA-Z]/.test(val)) {
      if (index < otpFieldsRefs.current.length - 1 && val) {
        setTimeout(() => otpFieldsRefs.current[index + 1].focus(), 100);
      }
      const newOtpValues = [...otpValues];
      newOtpValues[index] = val;
      setOtpValues(newOtpValues);
    } else {
      e.target.value = "";
    }
  };
  const otpOnChangeHandlermobile = (otp, data) => {
    console.log(otp, data, "otpOnChangeHandlermobile55")
    setmobileotp(otp)

  }

  const otpOnKeydownHandler = (e, index) => {
    if (e.key === "Backspace" && index > 0) {
      const newOtpValues = [...otpValues];
      for (let i = index; i >= 0; i--) {
        newOtpValues[i] = ''; // Clear the values from the current field up to the first field
      }
      setOtpValues(newOtpValues);
      setTimeout(() => otpFieldsRefs.current[0].focus(), 100); // Focus on the first field
    }
  };

  const otpOnSubmit = async (e) => {
    if (check === true) {
      const otp = otpFieldsRefs.current.map(cur => cur.value).join('');
      await setOTPPayload({
        user_uuid: userDetails.user_uuid ? userDetails?.user_uuid : '',
        code: otp
      });
      // setOpen(true)
    }
    else {
      setOpen1(true)
    }
  }
  const mobileotpOnSubmit = async (e) => {
    if (check === true) {
      // const otp = otpFieldsRefs.current.map(cur => cur.value).join('');
      await setOTPPayload({
        user_uuid: userDetails.user_uuid ? userDetails?.user_uuid : '',
        code: mobileotp
      });
      // setOpen(true)
    }
    else {
      setOpen1(true)
    }
  }

  const redirectToTermspage = () => {
    setOpenPopup(true)
    // navigate('/terms')
  }

  useEffect(() => {
    if (isOTPVerified) {
      setOpen(true)
      setTimeout(() => {
        navigate("/home")
      }, 1000)
    }
    else if (isOTPVerified === false) {
      setOpen(true)
    }
  }, [isOTPVerified])
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])


  return (
    <SignInTemplate>
      <div css={classes.container}>
        {mobileView&&
              <div style={{ margin: '2% 0', textAlign: 'center' }}><img height={40} src={appLogo} alt="money mails logo" /></div> 

        }
        <div>
          <h1 css={classes.heading}>Verification</h1>
          <Typography css={classes.subText}>Insert your code here:</Typography>
        </div>
        {!mobileView &&
          <div css={classes.otpFieldsWrapper}>
            {new Array(8).fill("").map((_item, idx) => (
              <input
                type="text"
                key={uuid()}
                ref={(el) => (otpFieldsRefs.current[idx] = el)}
                maxLength={1}
                css={classes.otpField}
                value={otpValues[idx]}
                onChange={(e) => otpOnChangeHandler(e, idx)}
                onKeyDown={(e) => otpOnKeydownHandler(e, idx)}
              />
            ))}
          </div>
        }
        {mobileView &&
          //  <div css={classes.otpFieldsWrapper}>
          //     {new Array(8).fill("").map((_item, idx) => (
          //       <input
          //         type="text"
          //         key={uuid()}
          //         ref={(el) => (otpFieldsRefs.current[idx] = el)}
          //         maxLength={1}
          //         css={classes.otpField}
          //         value={otpValues[idx]}
          //         onChange={(e) => otpOnChangeHandler(e, idx)}
          //         onKeyDown={(e) => otpOnKeydownHandler(e, idx)}
          //       />
          //     ))}
          //   </div>
          // <div css={classes.otpFieldsWrapper}>
            <div className="mb-6 filled form-group tooltip-end-top">
              {/* <CsLineIcons icon="mobile" /> */}
              <Form.Control type="text" name="mobile" placeholder="Enter Code" minLength={8} maxLength={8} onChange={(e) => otpOnChangeHandlermobile(e.target.value)} />
              {/* {errors.mobile && touched.mobile && <div className="d-block invalid-tooltip">{errors.mobile}</div>} */}
            </div>
          // </div>
        }
        {/* <div style={{ display: "inline-flex" }}>
          <input type="checkbox" checked={check === true} onClick={change} style={{
            width: 20,
            height: 20,
            border: 0
          }}></input><Typography onClick={redirectToTermspage} style={{
            fontWeight: 100,
            paddingTop: 5,
            margin: 0,
            color: "black",
            fontSize: 20,
            marginTop: "-10px",
            cursor: "pointer"
          }}>&nbsp;Terms and Conditions</Typography>
        </div> */}
        {/* {mobileView && */}
        <div style={{ overflowY: "scroll", height: "170px", WebkitOverflowScrolling: "touch" }}>
          <div style={{ textAlign: "justify" }}>
            {/* <div style={{ margin: '2% 0', textAlign: 'center' }}><img height={20} src={appLogo} alt="money mails logo" /></div> */}
            <h5 style={{ textAlign: 'center' }}>TERMS & CONDITIONS</h5>
            <p>Please read these terms and conditions carefully before using https://money-mails.com. This website is operated and managed by money-mails Inc in the whole extent of this website, every point the terms “we”, “us” or “our” is used, it refers to money-mails Inc.
              By purchasing goods from us, you involve in our “Service” and agree to be bound by the following terms and conditions stated, including the policies referenced herein and/or available by hyperlink. These Terms and Conditions are applicable to all users of the site, including without limitation every user who is browsers, customers and merchants. If you disagree with all or any of the terms of use in this agreement, then you may consider not to access the website or use any of the services.
            </p>
            <h6>CHANGES TO TERMS</h6>
            <p>We are committed to ensuring that our online store is as useful and efficient as possible. For that reason, we reserve the right to make changes to the services, at any time. We will never charge you for any service without making it very clear to you precisely what you're paying for.</p>
            <p>Any new features or product(s) which are added to the current store shall also be subject to this Terms and condition. You can always review the most current version of the Terms and conditions at any time on this page. We reserve the right to update, change or retrieve any part of these Terms and conditions by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
            <h6>PLACING AN ORDER</h6>
            <p>When you place an order through the Website, we will send an email to confirm that we have received the order. All orders are subject to these terms and conditions. Orders placed through the Website represent an offer to purchase a product. It is accepted for each product when we send you an email to confirm that the order has been received. We reserve the right to reject any order for any reason.
              It is important that you enter the correct e-mail address when placing your order. We also recommend that you save your order confirmation email to facilitate any contact with customer service or for future references. The order confirmation (receipt) also serves as a guarantee of proof of purchase.
            </p><h6>PRICES, TAXES, AND PAYMENT TERMS</h6>
            <p>Prices and payment details of products are specified on the website. Payment can be made by credit card, Debit card, and/or as further described in the store. Prices are shown exclusive of VAT, import duties and other government-imposed taxes, duties, and levies. You will be responsible for the payment of such import duties and taxes. It should be noted that money-mails Inc has no control over these charges.
              The User guarantees that the information submitted when using the Service, including without limitation, its payment details, shall be complete, correct, truthful and up to date.
            </p>
            <p>The User must inform money-mails Inc immediately about any inaccuracies in the offer provided, including the price. Our banking services and payment gateways manage all of our banking transactions. We don’t store any credit card numbers. Your payment is handled by the payment gateways with secure encryption and under strict banking standards. Your card details are sent directly to them and cannot be read or accessed by anyone.</p>
            <p>During the period of validity indicated in the offer for the product, the prices of the product will not be increased, except for price changes in VAT-tariffs. After such a period, we are entitled to adjust the price for any product(s). In such cases, money-mails Inc shall notify the User in advance. Such notice may be provided at any time by posting the changes to the Website or via the product itself.</p>
            <h6>OUR RIGHTS TO CANCEL/REJECT ORDERS</h6>
            <p>We reserve the right to decline any order you place with us. We may, in our exclusive discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, or orders that use the same billing and/or shipping address. If we make a change to or cancel an order, we may attempt to notify you by contacting the e¬mail and billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all purchases made at our store.</p>
            <h6>PRODUCT AVAILABILITY, ERRORS, INACCURACIES AND OMISSIONS</h6>
            <p>Products on our online store through https://money-mails.com are subject to change without notice. Errors will be corrected when discovered. Our Site contains a large number of products and it is always possible that, despite our best efforts, some of the products listed on our Site may be incorrectly priced, the quantity or availability of a product may have changed just prior to you placing your order or other errors may be displayed on the product page. We will normally verify prices, availability and confirm there are no errors on the product page as part of our dispatch procedures.</p>
            <p>Where a product's correct price is less than our stated price, we will charge the lower amount when dispatching the product to you. If a product's correct price is higher than the price stated on our Site, we will normally, at our discretion, either contact you for instructions before dispatching the Product, or reject your order and notify you of such rejection. We are under no obligation to provide the product to you at the incorrect (lower) price, even after we have sent you an Order Confirmation.</p>
            <p>Also, there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions on a product page. We reserve the right to rescind our acceptance and cancel your order without penalty in the event there is an obvious and unmistakable error on the product page, in our reasonable discretion.</p>
            <h6>VENDOR TERMS</h6>
            <h6>Fees and Billing</h6>
            <p>Joining and listing of products on money-mails Inc is free. money-mails Inc charges a fee and a percentage of the sale price when the item sells. When you list an item, you have an opportunity to review and accept the fees that you will be charged. money-mails Fees Policy, which is subject to change, is incorporated into this Agreement by reference. Changes to the Fees Policy and the fees for money-mails services are effective after money-mails provides you with at least fourteen (14) days’ notice by posting the changes on the Site. However, money-mails Inc may choose to temporarily change the Fees Policy and the fees for money-mails services for promotional events. Such changes are effective when money-mails Inc posts the temporary promotional event on the Site. money-mails Inc may, at their sole discretion, change some or all of money-mails Inc services at any time. In the event money-mails Inc introduces a new service, the fees for that service are effective at the launch of the service.</p>
            <h6>Fees and termination</h6>
            <p>If money-mails Inc terminates a listing or your account, if you close your account, or if the payment of your money-mails Inc fees cannot be completed for any reason, you remain obligated to pay money-mails Inc for all unpaid fees plus any penalties, if applicable. If the seller’s account is not paid in full and becomes past due, the seller risks penalties such as the suspension of privileges and/or termination of the account and other collection mechanisms (including retaining collection agencies and legal counsel). If you have a question or wish to dispute a charge, contact money-mails Inc.</p>
            <h6>Listing and selling</h6>
            <p>By listing an item on the website, you warrant that you and all aspects of the item comply with money-mails Inc published policies. You also warrant that you may legally sell the item. You must accurately describe your item and all terms of sale in your money-mails Inc profile. Your listings may only include text descriptions, graphics, pictures and other content relevant to the sale of that item. All items must be listed in an appropriate category with appropriate tags. Each listing must accurately and completely describe the item/items for sale in that listing. If the “in stock” quantity is more than one, all items in that listing must be identical. Each unique item must have its own listing.</p>
            <h6>Restricted activities:</h6>
            <h6>Your Content and your use of money-mails INC shall not:</h6>
            <p>Be false, inaccurate or misleading
              Be fraudulent or involve the sale of illegal, counterfeit or stolen items
              Infringe upon any third-party’s copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of publicity or privacy
              Violate this Agreement, any site policy, or any applicable law, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising)
              Furthermore, you may not list any item on money-mails INC (or consummate any transaction that was initiated using money-mails INC’s service) that, by paying to money-mails INC the listing fee or the final value fee, could cause money-mails INC to violate any applicable law, statute, ordinance or regulation, or that violates the Terms of Use.
            </p>
            <h6>DPRICING POLICY</h6>
            <p>Please note that faith is the most important aspect of trade between buyers and sellers in a business place and one of the key aspects of faith is fair pricing. For healthy transaction flow at money-mails Inc, we ask you to price your items responsibly and reasonably.
              Please note that we are constantly monitoring prices for each merchant at various channels and do not allow you to price your items at higher prices at money-mails Inc vs. other channels such as your own retail store, your own Website (if any) or any other 3rd party retail stores or Websites. You should price your items sold at money-mails Inc to be same as the lowest rate you are selling at elsewhere or even lower but it should not be more. Violation of our pricing policy will result into warning notices and can also result into account suspension and/or store suspension.
            </p>
            <h6>BUYER PROTECTION</h6>
            <p>As an online market place, money-mails Inc runs a very supportive Buyer security program so that buyer is totally protected dealing with money-mails Inc. At money-mails Inc, trust is the biggest thing for each dealing. To extend this trust to buyers, we offer Buyer security, which is based on four trust pillars i.e.</p>
            <p><strong>Payment Security -</strong> Your payment is not released to merchant unless you have received your item.</p>
            <p><strong>Product Guarantee -</strong> All products sold at money-mails Inc are new, 100% genuine and manufacturer warranty wherever applicable.</p>
            <p><strong>Easy Replacement -</strong> For wrong size, color, quantity, style, manufacturing, defects, damaged or significantly different from product listing.</p>
            <p><strong>Reliable Merchants -</strong> All the merchants go through a rigorous verification process before having their online store at money-mails Inc.</p>
            <p>All the merchants selling at money-mails Inc are very well observed and then they are allowed to business on our site. We see to it that all the products you buy from our website are genuine, authentic, products being sold by well reputed and tested merchants at the best prices possible. Of course, we also offer all the warranties, guarantees as entailed by the product, all this at a great price and with the best customer service in the industry.</p>
            <p>At money-mails Inc, we believe that customer satisfaction is key- therefore we stand behind all merchandise bought at our site. We will replace any order within 7 days of delivery, with free return shipping if you receive a product which is not as per the specifications ordered by you:</p>
            <ul>
              <li>Wrong color</li>
              <li>Wrong size</li>
              <li>Wrong style</li>
              <li>Wrong quantity</li>
              <li>Missing parts/accessories</li>
              <li>Defective</li>
              <li>Dead on arrival</li>
              <li>Damaged</li>
              <li>Significantly different from the description given by the merchant</li>
            </ul>
            <p>However, do make sure that the product has not been used/worn or altered/tampered with, as well as is accompanied by its original price tag and packing slip. Please contact our customer care team, so we can help facilitate your return.</p>
            <p>In case of pre-shipment delays, money-mails Inc also offers you choice to cancel an order prior to its shipment by contacting us at Customer care team. Our trust policies define who we are and we promise the best online shopping experience to our customers.</p>
            <h6>PROMO CODES AND OFFERS</h6>
            <p>Promo Codes and Offers are made at our sole discretion and are subject to time validity, a valid Promotional Code should be entered during the checkout process for a product to be valid. Attempting to add a promo code after purchase or after the expiration of the code will not be acknowledged.</p>
            <h6>AGE OF CONSENT</h6>
            <p>By agreeing to these Terms of Use, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence. You may not use/access our products for any illegal or unauthorized purpose nor may you, in the use of the Service or online store, violate any laws in your jurisdiction (including but not limited to copyright laws). A breach or violation of any of the Terms will result in an immediate termination of your access to our products or services.</p>
            <h6>SERVICE TERMS</h6>
            <p><strong>money-mails Inc</strong> at this moment grants the User a non-exclusive, non-transferable, limited right to access and use the Service, under the conditions of these Terms & Conditions and for the duration of the Agreement.</p>
            <p>The use of the Service is at the User's own expense and risk. The User is responsible for meeting the technical and functional requirements and using the electronic communication facilities that are necessary to be able to access and use the Service. The User will at all times bear the risk of loss, theft or damage to any of its data.</p>
            <p><strong>money-mails Inc</strong> will have the right (but not the obligation), at its sole discretion, to review, edit, limit, refuse or remove Content, products or to limit or refuse the User access to the Service. More specifically in the event the use of the Service, according to us, violates these Terms of Use.</p>
            <p>We may disclose the User's Personal Data or Content, or other data relating to the use of the Service, to third parties where it believes, in good faith, that it is necessary to comply with a court order, ongoing judicial proceeding, criminal or civil subpoena, or other legal process or request by law enforcement authorities, or to exercise its constitutional rights of defense against legal claims.</p>
            <h6>THIRD-PARTY LINKS</h6>
            <p>Certain content and services available via our website may include materials from third-parties. Third-party links on this site may direct you to third-party websites or applications that are not affiliated with us. <strong>money-mails Inc</strong> is not responsible for examining or evaluating the content or accuracy of such website or application and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites.</p>
            <p>You’re obliged to review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
            <h6>PERSONAL INFORMATION</h6>
            <p>Your submission of personal information through the store is regulated by our Privacy Policy. You are to view our <Link to="/privacy" target="_blank">Privacy Policy.</Link></p>
          </div>
        </div>
        {/* } */}

        <div className="form-check mb-4 mt-3">
          <input type="checkbox" className="form-check-input" style={{ border: "2px solid #329d9c" }} name="terms" checked={check === true} onClick={change} />
          <label className="form-check-label">
            I have read and accept the <NavLink style={{ cursor: "pointer", color: "#00adef" }} onClick={redirectToTermspage}> Terms and Conditions.</NavLink>
          </label>
        </div>
        {/* {!mobileView & */}
        {mobileView &&
          <div>

            {/* <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              onClick={otpOnSubmit}
              sx={{
                maxWidth: 300,
              }}
            >
              Continue
            </Button> */}

            <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              onClick={mobileotpOnSubmit}
              sx={{
                maxWidth: 300,
              }}
            >
              Continue
            </Button>

            <div css={classes.textBtnWrapper}>
              {/* <Button type="buton" variant="text" fullWidth color="primary">
              Resend code
            </Button> */}
              {/* <Button
              type="button"
              variant="text"
              fullWidth
              color="primary"
              onClick={() => navigate("/reset-password")}
            >
              Change number
            </Button> */}
            </div>
          </div>
        }
        {!mobileView &&
          <div>

            <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              onClick={otpOnSubmit}
              sx={{
                maxWidth: 300,
              }}
            >
              Continue
            </Button>

            {/* <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              onClick={mobileotpOnSubmit}
              sx={{
                maxWidth: 300,
              }}
            >
              Continue
            </Button> */}

            <div css={classes.textBtnWrapper}>
              {/* <Button type="buton" variant="text" fullWidth color="primary">
              Resend code
            </Button> */}
              {/* <Button
              type="button"
              variant="text"
              fullWidth
              color="primary"
              onClick={() => navigate("/reset-password")}
            >
              Change number
            </Button> */}
            </div>
          </div>
        }
        {/* } */}
        <Dialog
          open={openpopup}
          keepMounted
          onClose={handleClose1}
          maxWidth='md'
          // maxWidth="lg"
          fullWidth='true'
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Card className="mb-12" >
                <Card.Body>
                  <div style={{ overflowY: "scroll", height: "450px" }}>
                    <div style={{ textAlign: "justify" }}>
                      {/* <div style={{ margin: '2% 0', textAlign: 'center' }}><img height={20} src={appLogo} alt="money mails logo" /></div> */}
                      <h5 style={{ textAlign: 'center' }}>TERMS & CONDITIONS</h5>
                      <p>Please read these terms and conditions carefully before using https://money-mails.com. This website is operated and managed by money-mails.com in the whole extent of this website, every point the terms “we”, “us” or “our” is used, it refers to money-mails.com.
                        By purchasing goods from us, you involve in our “Service” and agree to be bound by the following terms and conditions stated, including the policies referenced herein and/or available by hyperlink. These Terms and Conditions are applicable to all users of the site, including without limitation every user who is browsers, customers and merchants. If you disagree with all or any of the terms of use in this agreement, then you may consider not to access the website or use any of the services.
                      </p>
                      <h6>CHANGES TO TERMS</h6>
                      <p>We are committed to ensuring that our online store is as useful and efficient as possible. For that reason, we reserve the right to make changes to the services, at any time. We will never charge you for any service without making it very clear to you precisely what you're paying for.</p>
                      <p>Any new features or product(s) which are added to the current store shall also be subject to this Terms and condition. You can always review the most current version of the Terms and conditions at any time on this page. We reserve the right to update, change or retrieve any part of these Terms and conditions by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                      <h6>PLACING AN ORDER</h6>
                      <p>When you place an order through the Website, we will send an email to confirm that we have received the order. All orders are subject to these terms and conditions. Orders placed through the Website represent an offer to purchase a product. It is accepted for each product when we send you an email to confirm that the order has been received. We reserve the right to reject any order for any reason.
                        It is important that you enter the correct e-mail address when placing your order. We also recommend that you save your order confirmation email to facilitate any contact with customer service or for future references. The order confirmation (receipt) also serves as a guarantee of proof of purchase.
                      </p><h6>PRICES, TAXES, AND PAYMENT TERMS</h6>
                      <p>Prices and payment details of products are specified on the website. Payment can be made by credit card, Debit card, and/or as further described in the store. Prices are shown exclusive of VAT, import duties and other government-imposed taxes, duties, and levies. You will be responsible for the payment of such import duties and taxes. It should be noted that money-mails.com has no control over these charges.
                        The User guarantees that the information submitted when using the Service, including without limitation, its payment details, shall be complete, correct, truthful and up to date.
                      </p>
                      <p>The User must inform money-mails.com immediately about any inaccuracies in the offer provided, including the price. Our banking services and payment gateways manage all of our banking transactions. We don’t store any credit card numbers. Your payment is handled by the payment gateways with secure encryption and under strict banking standards. Your card details are sent directly to them and cannot be read or accessed by anyone.</p>
                      <p>During the period of validity indicated in the offer for the product, the prices of the product will not be increased, except for price changes in VAT-tariffs. After such a period, we are entitled to adjust the price for any product(s). In such cases, money-mails.com shall notify the User in advance. Such notice may be provided at any time by posting the changes to the Website or via the product itself.</p>
                      <h6>OUR RIGHTS TO CANCEL/REJECT ORDERS</h6>
                      <p>We reserve the right to decline any order you place with us. We may, in our exclusive discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, or orders that use the same billing and/or shipping address. If we make a change to or cancel an order, we may attempt to notify you by contacting the e¬mail and billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all purchases made at our store.</p>
                      <h6>PRODUCT AVAILABILITY, ERRORS, INACCURACIES AND OMISSIONS</h6>
                      <p>Products on our online store through https://money-mails.com are subject to change without notice. Errors will be corrected when discovered. Our Site contains a large number of products and it is always possible that, despite our best efforts, some of the products listed on our Site may be incorrectly priced, the quantity or availability of a product may have changed just prior to you placing your order or other errors may be displayed on the product page. We will normally verify prices, availability and confirm there are no errors on the product page as part of our dispatch procedures.</p>
                      <p>Where a product's correct price is less than our stated price, we will charge the lower amount when dispatching the product to you. If a product's correct price is higher than the price stated on our Site, we will normally, at our discretion, either contact you for instructions before dispatching the Product, or reject your order and notify you of such rejection. We are under no obligation to provide the product to you at the incorrect (lower) price, even after we have sent you an Order Confirmation.</p>
                      <p>Also, there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions on a product page. We reserve the right to rescind our acceptance and cancel your order without penalty in the event there is an obvious and unmistakable error on the product page, in our reasonable discretion.</p>
                      <h6>VENDOR TERMS</h6>
                      <h6>Fees and Billing</h6>
                      <p>Joining and listing of products on money-mails.com is free. money-mails.com charges a fee and a percentage of the sale price when the item sells. When you list an item, you have an opportunity to review and accept the fees that you will be charged. money-mails Fees Policy, which is subject to change, is incorporated into this Agreement by reference. Changes to the Fees Policy and the fees for money-mails services are effective after money-mails provides you with at least fourteen (14) days’ notice by posting the changes on the Site. However, money-mails.com may choose to temporarily change the Fees Policy and the fees for money-mails services for promotional events. Such changes are effective when money-mails.com posts the temporary promotional event on the Site. money-mails.com may, at their sole discretion, change some or all of money-mails.com services at any time. In the event money-mails.com introduces a new service, the fees for that service are effective at the launch of the service.</p>
                      <h6>Fees and termination</h6>
                      <p>If money-mails.com terminates a listing or your account, if you close your account, or if the payment of your money-mails.com fees cannot be completed for any reason, you remain obligated to pay money-mails.com for all unpaid fees plus any penalties, if applicable. If the seller’s account is not paid in full and becomes past due, the seller risks penalties such as the suspension of privileges and/or termination of the account and other collection mechanisms (including retaining collection agencies and legal counsel). If you have a question or wish to dispute a charge, contact money-mails.com.</p>
                      <h6>Listing and selling</h6>
                      <p>By listing an item on the website, you warrant that you and all aspects of the item comply with money-mails.com published policies. You also warrant that you may legally sell the item. You must accurately describe your item and all terms of sale in your money-mails.com profile. Your listings may only include text descriptions, graphics, pictures and other content relevant to the sale of that item. All items must be listed in an appropriate category with appropriate tags. Each listing must accurately and completely describe the item/items for sale in that listing. If the “in stock” quantity is more than one, all items in that listing must be identical. Each unique item must have its own listing.</p>
                      <h6>Restricted activities:</h6>
                      <h6>Your Content and your use of money-mails INC shall not:</h6>
                      <p>Be false, inaccurate or misleading
                        Be fraudulent or involve the sale of illegal, counterfeit or stolen items
                        Infringe upon any third-party’s copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of publicity or privacy
                        Violate this Agreement, any site policy, or any applicable law, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising)
                        Furthermore, you may not list any item on money-mails INC (or consummate any transaction that was initiated using money-mails INC’s service) that, by paying to money-mails INC the listing fee or the final value fee, could cause money-mails INC to violate any applicable law, statute, ordinance or regulation, or that violates the Terms of Use.
                      </p>
                      <h6>DPRICING POLICY</h6>
                      <p>Please note that faith is the most important aspect of trade between buyers and sellers in a business place and one of the key aspects of faith is fair pricing. For healthy transaction flow at money-mails.com, we ask you to price your items responsibly and reasonably.
                        Please note that we are constantly monitoring prices for each merchant at various channels and do not allow you to price your items at higher prices at money-mails.com vs. other channels such as your own retail store, your own Website (if any) or any other 3rd party retail stores or Websites. You should price your items sold at money-mails.com to be same as the lowest rate you are selling at elsewhere or even lower but it should not be more. Violation of our pricing policy will result into warning notices and can also result into account suspension and/or store suspension.
                      </p>
                      <h6>BUYER PROTECTION</h6>
                      <p>As an online market place, money-mails.com runs a very supportive Buyer security program so that buyer is totally protected dealing with money-mails.com. At money-mails.com, trust is the biggest thing for each dealing. To extend this trust to buyers, we offer Buyer security, which is based on four trust pillars i.e.</p>
                      <p><strong>Payment Security -</strong> Your payment is not released to merchant unless you have received your item.</p>
                      <p><strong>Product Guarantee -</strong> All products sold at money-mails.com are new, 100% genuine and manufacturer warranty wherever applicable.</p>
                      <p><strong>Easy Replacement -</strong> For wrong size, color, quantity, style, manufacturing, defects, damaged or significantly different from product listing.</p>
                      <p><strong>Reliable Merchants -</strong> All the merchants go through a rigorous verification process before having their online store at money-mails.com.</p>
                      <p>All the merchants selling at money-mails.com are very well observed and then they are allowed to business on our site. We see to it that all the products you buy from our website are genuine, authentic, products being sold by well reputed and tested merchants at the best prices possible. Of course, we also offer all the warranties, guarantees as entailed by the product, all this at a great price and with the best customer service in the industry.</p>
                      <p>At money-mails.com, we believe that customer satisfaction is key- therefore we stand behind all merchandise bought at our site. We will replace any order within 7 days of delivery, with free return shipping if you receive a product which is not as per the specifications ordered by you:</p>
                      <ul>
                        <li>Wrong color</li>
                        <li>Wrong size</li>
                        <li>Wrong style</li>
                        <li>Wrong quantity</li>
                        <li>Missing parts/accessories</li>
                        <li>Defective</li>
                        <li>Dead on arrival</li>
                        <li>Damaged</li>
                        <li>Significantly different from the description given by the merchant</li>
                      </ul>
                      <p>However, do make sure that the product has not been used/worn or altered/tampered with, as well as is accompanied by its original price tag and packing slip. Please contact our customer care team, so we can help facilitate your return.</p>
                      <p>In case of pre-shipment delays, money-mails.com also offers you choice to cancel an order prior to its shipment by contacting us at Customer care team. Our trust policies define who we are and we promise the best online shopping experience to our customers.</p>
                      <h6>PROMO CODES AND OFFERS</h6>
                      <p>Promo Codes and Offers are made at our sole discretion and are subject to time validity, a valid Promotional Code should be entered during the checkout process for a product to be valid. Attempting to add a promo code after purchase or after the expiration of the code will not be acknowledged.</p>
                      <h6>AGE OF CONSENT</h6>
                      <p>By agreeing to these Terms of Use, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence. You may not use/access our products for any illegal or unauthorized purpose nor may you, in the use of the Service or online store, violate any laws in your jurisdiction (including but not limited to copyright laws). A breach or violation of any of the Terms will result in an immediate termination of your access to our products or services.</p>
                      <h6>SERVICE TERMS</h6>
                      <p><strong>money-mails.com</strong> at this moment grants the User a non-exclusive, non-transferable, limited right to access and use the Service, under the conditions of these Terms & Conditions and for the duration of the Agreement.</p>
                      <p>The use of the Service is at the User's own expense and risk. The User is responsible for meeting the technical and functional requirements and using the electronic communication facilities that are necessary to be able to access and use the Service. The User will at all times bear the risk of loss, theft or damage to any of its data.</p>
                      <p><strong>money-mails.com</strong> will have the right (but not the obligation), at its sole discretion, to review, edit, limit, refuse or remove Content, products or to limit or refuse the User access to the Service. More specifically in the event the use of the Service, according to us, violates these Terms of Use.</p>
                      <p>We may disclose the User's Personal Data or Content, or other data relating to the use of the Service, to third parties where it believes, in good faith, that it is necessary to comply with a court order, ongoing judicial proceeding, criminal or civil subpoena, or other legal process or request by law enforcement authorities, or to exercise its constitutional rights of defense against legal claims.</p>
                      <h6>THIRD-PARTY LINKS</h6>
                      <p>Certain content and services available via our website may include materials from third-parties. Third-party links on this site may direct you to third-party websites or applications that are not affiliated with us. <strong>money-mails.com</strong> is not responsible for examining or evaluating the content or accuracy of such website or application and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites.</p>
                      <p>You’re obliged to review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                      <h6>PERSONAL INFORMATION</h6>
                      <p>Your submission of personal information through the store is regulated by our Privacy Policy. You are to view our <Link to="/privacy" target="_blank">Privacy Policy.</Link></p>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;
                  <div>
                    <Button
                      type="button"
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={handleClose1}
                      style={{ marginLeft: "42%" }}
                      sx={{
                        maxWidth: 100,
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </DialogContentText>
          </DialogContent>

        </Dialog >
        <ThemeProvider theme={theme}>
          <MuiSnackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            <Alert severity={error ? 'error' : 'success'}>
              {isOTPVerified ? "User Verified !" : message}
            </Alert>
          </MuiSnackbar>
        </ThemeProvider>

        <ThemeProvider theme={theme}>
          <MuiSnackbar open={open1} autoHideDuration={3000} onClose={() => setOpen1(false)} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            <Alert severity={'error'}>
              {"Please Accept Terms and Conditions"}
            </Alert>
          </MuiSnackbar>
        </ThemeProvider>
      </div >
    </SignInTemplate >
  );
};

export default Verification;
