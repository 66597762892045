import useGetColors from "../../utils/colors";

export const useGetOfferCardStyles = ({ size, maxSize, height, maxHeight, mobile }) => {
  const { offerCardColors } = useGetColors();

  const defaultSize = 220;
  const cardSize = size || defaultSize;
  const maxDimensions = maxSize || cardSize;

  const cardSize1 = height || defaultSize;
  const maxDimensions1 = maxHeight || cardSize;

  return {
    container: {
      backgroundColor: offerCardColors.cardBgColor,
      width: cardSize,
      height: mobile ? cardSize1 : cardSize,
      borderRadius: 12,
      maxWidth: maxDimensions,
      maxHeight: mobile ? maxDimensions1 : maxDimensions,
      border: `thin solid ${offerCardColors.cardBorderColor}`,
      "&:hover": {
        borderColor: offerCardColors.cardBorderHighlightColor,
      },
      position: 'relative',
      overflow: 'hidden',
      '@media (max-width: 425px)': {
        maxWidth: '100%',
      },
    },
    wrapper: {
      padding: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    cardHeaderWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    favIcon: {
      marginTop: '-5px'
    },
    offerTitleWrapper: {
      paddingTop: 5,
      display: "flex",
      flexDirection: "column",
      alignItems: 'center'
    },
    offerTitle: {
      color: offerCardColors.cardTitleColor,
      fontSize: 14,
    },
    offerSubText: {
      color: offerCardColors.cardSubTextColor,
      fontSize: 14,
    },
    cardFooterText: {
      fontSize: 18,
      color: offerCardColors.cardSubTextColor,
      cursor: 'pointer'
    },
    redeemTag: {
      backgroundColor: offerCardColors.redeemedBG,
      color: offerCardColors.redeemedFont,
      padding: "8px 12px",
      borderRadius: 4,
      marginTop: 10
    },
    cardFooter: {
      position: 'absolute',
      bottom: 0,
      padding: '5px 10px',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      color: '#fff',
      textAlign: 'center',
      height: '30px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  };
};
