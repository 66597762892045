/** @jsxImportSource @emotion/react */
import Button from "../../components/Button";
import { useNavigate, Link } from "react-router-dom";
import signUpSVG from "../../assets/images/illustrations/signUp.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppBar from "../../components/AppBar";
import { Grid } from "@mui/material";
import React, { useState, useEffect } from 'react';
 
 
const Instructions = () => {
 
    const navigate = useNavigate();
 
    const RedirectFunction = () => {
        setTimeout(() => {
            navigate("/login")
        }, 1000)
    }
 
    const [mobileView, setMobileView] = useState(false);
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 767
                ? setMobileView(true)
                : setMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
 
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, [])
 
 
 
    return (
        <>
            {/* <AppBar /> */}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                {!mobileView &&
                    <>
                        <div style={{ marginRight: "10%" }}>
                            <img
                                src={signUpSVG}
                                alt="sign up illustration"
                                width="100%"
                                height="550px"
                            />
                        </div>
 
                        <div>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <h2 style={{ display: "flex", marginBottom: "50px" }}>Instructions</h2>
                                <h6 style={{ display: "flex", marginBottom: "30px" }}>Follow these steps to verify and log in:</h6>
                                <div>
                                    <p style={{ display: "flex", marginBottom: "20px" }}>1. Expect to receive a verification code at your registered address shortly. Please be patient while waiting for the verification code to arrive. </p>
                                    <p style={{ display: "flex", marginBottom: "20px" }}>2. Once you've received the verification code, proceed to log in using your credentials. </p>
                                    <p style={{ display: "flex", marginBottom: "20px" }}>3.  After successfully logging in, you will need to complete the verification process using the received code. </p>
                                    <p style={{ display: "flex", marginBottom: "20px" }}>4. In case you do not receive the code, please reach out to the administrator for assistance. </p>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    // sx={{ width: 1 / 3 }}
                                    onClick={RedirectFunction}
                                >
                                    {/* <div style={{ height: '20px', display: "flex", justifyContent: "space-between" }}> */}
                                    <span>Back</span>
                                    <ArrowForwardIcon />
                                    {/* </div> */}
                                </Button>
                            </form>
                        </div>
                    </>
                }
                {mobileView &&
                    <div className="container">
                        <form onSubmit={(e) => e.preventDefault()} className="form">
                            <h2 className="heading">Instructions</h2>
                            <h6 className="subheading">Follow these steps to verify and log in:</h6>
                            <div className="instructions">
                                <p>1. Expect to receive a verification code at your registered address shortly. Please be patient while waiting for the verification code to arrive.</p>
                                <p>2. Once you've received the verification code, proceed to log in using your credentials.</p>
                                <p>3. After successfully logging in, you will need to complete the verification process using the received code.</p>
                                <p>4. In case you do not receive the code, please reach out to the administrator for assistance.</p>
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={RedirectFunction}
                                className="button"
                            >
                                <span>Back</span>
                                <ArrowForwardIcon />
                            </Button>
                        </form>
 
                        <style jsx>{`
                        .container {
                            padding: 20px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                        .form {
                            width: 100%;
                            max-width: 600px;
                            display: flex;
                            flex-direction: column;
                        }
                        .heading {
                            display: flex;
                            margin-bottom: 50px;
                            text-align: center;
                        }
                        .subheading {
                            display: flex;
                            margin-bottom: 30px;
                            text-align: center;
                        }
                        .instructions {
                            display: flex;
                            flex-direction: column;
                        }
                        .instructions p {
                            display: flex;
                            margin-bottom: 20px;
                        }
                        .button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 20px;
                            width: 100%;
                        }
       
                        @media (max-width: 768px) {
                            .heading {
                                font-size: 1.5em;
                            }
                            .subheading {
                                font-size: 1em;
                            }
                            .instructions p {
                                font-size: 0.9em;
                            }
                            .button {
                                flex-direction: column;
                            }
                        }
                    `}</style>
                    </div>
                }
            </div>
 
        </>
    );
};
 
export default Instructions;
 
 