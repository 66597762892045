/** @jsxImportSource @emotion/react */
import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { useGetBackButtonStyles } from "./index.styles";

const BackButton = ({ size = 78, rounded, src, fit = "contain", flatStyle }) => {
  const styles = useGetBackButtonStyles({ size });
  const navigate = useNavigate();

  return (
    <span>
      <IconButton aria-label="back" size="small" css={styles.backArrow} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />Back
      </IconButton>
    </span>
  );
};

export default BackButton;



