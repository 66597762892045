/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext, useRef } from "react";
import { Typography, Drawer } from "@mui/material";
import AppBar from "../../components/AppBar";
import ReebokSVG from "../../icons/reebok";
import AdidasSvg from "../../icons/adidas";
import NikeSVG from "../../icons/nike";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useGetNavItems } from "../Home/appbarNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useGetRewardsScreenStyles } from "../Rewards/index.styles";
import Pagination from '../../components/Pagination';
import { Row, Col, Dropdown, Form, Card, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { baseUrl } from "../../connections";

import axios from 'axios'

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import BrandIconCard from "../../components/BrandIconCard";
import OfferCard from "../../components/OfferCard";
// import AppFooter from "../../components/AppFooter";
import { useFavourites } from "../../hooks/services/useFavourites";
import { Radio } from "antd";
import uuid from "react-uuid";
import { useMail } from "../../hooks/services/useMail";
import RunCounter from "../../components/RunCounter";
import Snackbar from "../../components/Snackbar";
import BackButton from "../../components/BackButton";
import { useInterval } from "../../hooks/useInterval";
import { SearchOutlined } from "@mui/icons-material";
import useGetColors from "../../utils/colors";
import { useLocation, useNavigate } from "react-router-dom";
import Footer1 from "../../components/Footer/Footer";
import { Notificationconsumer } from "../../hooks/services/notifications";


const colorsForBrands = ["#F2FCFF", "#F9FFF2", "#FFF2FB", "#FFEBDC"];
const arr = new Array(14).fill("").map((item, idx) => idx);
const brandSvgs = [NikeSVG, ReebokSVG, AdidasSvg];

const Favourites = () => {

    const navigate = useNavigate();

    const navigationView = (link) => {
        navigate(link)
    }

    const testcolor = () => {

    }
    const location = useLocation();

    console.log(location, "selCategory");


    const classes = useGetRewardsScreenStyles();
    const { userDetails } = useContext(UserContext);
    const { offerCardMultiColors: colors } = useGetColors();
    //   const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
    const { brandData = [], mailData = [], setFavPayload, setUpdateBrandFav, setUpdateMailFav, error } = useFavourites();
    const options = ["Brands", "Mails"];
    const [option, setOption] = useState(options[0]);
    const [searchValue, setSearchValue] = useState("");
    const offerDetailsCardRef = useRef(null);
    const [seconds, setSeconds] = useState(0);
    const { mailList, isRedeem, redeemMessage, redeemError, setMailPayload, mailHTML, setMailURL, setUpdateMailRedeem } = useMail();
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerContentRoute, setDrawerContentRoute] = useState("search");
    const [offerCard, setOfferCard] = useState({});
    const [selectedOfferIndex, setSelectedOfferIndex] = useState();

    const [numPages, setNumPages] = useState(1);
    const [isAnimate, setIsAnimate] = useState(true);
    const [pageNUmber, setPageNumber] = useState(1)

    const isOfferContentDrawerActive = drawerContentRoute === "offerCard";


    //   const { start, stop } = useInterval((count) => {
    //     setSeconds(count);
    //     if (count === 18) {
    //       setSeconds(0);
    //       setIsAnimate(true);
    //       stop();
    //     }
    //   });

    //   useEffect(() => {
    //     if(userDetails) setFavPayload(`pagenum=0&limit=100&user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}`);
    //   },[userDetails]);

    //   const onOptionChange = ({ target: { value } }) => {
    //     setOption(value);
    //     setSearchValue("");
    //   };

    //   useEffect(() => {
    //     if (isOfferContentDrawerActive) {
    //       start();
    //     }
    //   }, [isOfferContentDrawerActive]);

    //   const toggleDrawer = (event) => {
    //     if (
    //       (event.type === "keydown" &&
    //         (event.key === "Tab" || event.key === "Shift")) ||
    //       isOfferContentDrawerActive
    //     ) {
    //       return;
    //     }
    //     setShowDrawer(false);
    //   };


    // /notification/update/seen%27
    //   let userdata = JSON.parse(localStorage.getItem("user"));





    useEffect(() => {

        if (offerCard?.type === "paid") {
            setShowDrawer(true);
        }

    }, [redeemError, isRedeem])

    let userdata = JSON.parse(localStorage.getItem("user"));
    console.log(userdata, "userdata")

    const { noticationdata, count, setPayloadsdata, setpage, } = Notificationconsumer();
    console.log(noticationdata, "noticationdata11")

    useEffect(() => {
        setPayloadsdata(userdata?.uuid);
        setpage(`${pageNUmber - 1}`)
        // setStateChange(!stateChange)
    }, [pageNUmber, userdata?.uuid])

    const NotificationUpdateseen = (notifiid) => {
        const payload = {
            "notify_uuid": notifiid
        }
        axios.put(`${baseUrl}/notification/update/seen`, payload, {
            headers: {
                "x-auth-token": userdata && userdata?.token
            }
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <div css={classes.pageContainer} >
            <AppBar navItems={useGetNavItems({})} />
            <div css={classes.titleBanner}>
                <div style={{ justifyContent: 'flex-end' }}><BackButton /></div>
                <div><h1>Notifications</h1></div>
                <div></div>
            </div>
            <div css={classes.subWraper}>
                <div css={classes.radioGroup}>
                </div>
            </div>
          
            <Card  style={{ paddingLeft:"20px",paddingRight:"20px" ,marginLeft:"20px",marginRight:"20px", border:"0px" }}>
            
                <Row className="g-0 h-100 align-content-left d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort" >
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                        <div className="text-muted text-small cursor-pointer sort">SNO</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                        <div className="text-muted text-small cursor-pointer sort">From Date</div>
                    </Col>
                    <Col lg="2" className="d-flex flex-column pe-2 justify-content-center">
                        <div className="text-muted text-small cursor-pointer sort">Title</div>
                    </Col>
                    <Col lg="5" className="d-flex flex-column pe-2 justify-content-center">
                        <div className="text-muted text-small cursor-pointer sort">Message</div>
                    </Col>
                </Row>
                {noticationdata?.map((item, index) => {
                    console.log(item, "item232")
                   
                    return <Card div key="" className="mb-2" style={{ backgroundColor: item?.is_seen === true ? 'white' : '#B6B3B2' }} onClick={(event) => { navigationView(item.link); NotificationUpdateseen(item?.uuid) }}>
                        <Card.Body >
                          
                            <Row className="g-0 h-100 sh-lg-9 position-relative  mb-1" >

                                <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-2 order-lg-2">
                                    <div className="text-muted text-small d-lg-none">Sno</div>
                                    <div className="text-alternate"  >{index + 1}</div>
                                </Col>
                                <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-2 order-lg-2">
                                    <div className="text-muted text-small d-lg-none">date</div>
                                    <div className="text-alternate" >{moment(item.createdAt).format('YYYY-MM-DD')}</div>
                                </Col>
                                <Col xs="12" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-2 order-lg-2">
                                    <div className="text-muted text-small d-lg-none">Title</div>
                                    <div className="text-alternate" >{item?.title}</div>
                                </Col>
                                <Col xs="12" lg="5" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-2 order-lg-2">
                                    <div className="text-muted text-small d-lg-none" >Message</div>
                                    <div className="text-alternate" >
                                        {item?.message}
                                    </div>
                                </Col>
                            </Row>
                           
                        </Card.Body>
                    </Card>
                })}
             
            </Card>
            {/* <Pagination
                className="pagination-bar"
                currentPage={pageNUmber}
                totalCount={count}
                pageSize={10}
                onPageChange={page => setPageNumber(page)}
            /> */}
            <div style={{marginTop:"230px"}}>
          
            <Footer1 />
            </div>
        </div>

    );
};

export default Favourites;
