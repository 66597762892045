import useGetColors from "../../utils/colors";

export const useGetRewardsScreenStyles = () => {
  const { appBarColors, themeColors, rewardsScreenColors, searchScreenColors } = useGetColors();

  return {
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: themeColors.bg,
    },
    drawerContainer: {
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
        backgroundColor: "transparent",
      },
    },
    drawer: {
      backdropFilter: "blur(20px)",
      backgroundColor: appBarColors.drawerBg,
      minHeight: "30%",
      boxShadow: `0 3px 6px 0 ${appBarColors.appBarDropShadow}`,
      zIndex: '1255 !important'
    },
    titleBanner: {
      height: 120,
      background: rewardsScreenColors.titleBarBg,
      marginBottom: 18,
      display: "flex",
      alignItems: "center",
      padding: '0 80px',
      justifyContent: "space-between",
      color: themeColors.white,
      "& h1": {
        color: "inherit",
      },
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      padding: "0 35px",
      marginBottom: 10,
    },
    header: {
      backgroundColor: rewardsScreenColors.screenBg,
      display: "flex",
      gap: 50,
      padding: "32px 25px",
      alignItems: "center",
    },
    progressWrapper: {
      flex: 1,
    },
    userTypeTitle: {
      marginBottom: 8,
    },
    subWraper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    radioGroup: {
      margin: "5px 25px"
    },
    progressBarTitle: {
      fontSize: 11,
      color: rewardsScreenColors.textColor,
      marginTop: 8,
    },
    brandCardsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      margin: "30px auto",
      maxWidth: "90%",
      justifyContent: "center",
      gap: "15px",
      background: '#efefef'
    },
    balanceDetails: {
      display: "flex",
      flexDirection: "column",
      gap: 3,
    },
    balanceDetailsTextLight: {
      fontSize: 10,
      color: rewardsScreenColors.textColor,
      fontWeight: 400,
      display: "flex",
      gap: 5,
      alignItems: "center",
      "& > span": {
        opacity: 0.34,
      },
    },
    balanceDetailsTextStrong: {
      fontSize: 18,
      color: rewardsScreenColors.textColor,
      fontWeight: 500,
    },
    tandctag: {
      backgroundColor: rewardsScreenColors.tandcTagBg,
      color: rewardsScreenColors.textColor,
      padding: "0 8",
      fontSize: 11,
      border: 0,
      borderRadius: 10,
      cursor: "pointer",
    },
    redeemBtn: {
      maxWidth: 135,
      backgroundColor: rewardsScreenColors.redeemBtnBg,
      fontSize: 10,
      paddingTop: 0,
      paddingBottom: 0,
      height: 30,
      borderRadius: 30,
      "&:hover": {
        backgroundColor: rewardsScreenColors.redeemBtnBg,
      },
    },
    body: {
      backgroundColor: rewardsScreenColors.screenBg,
      display: "flex",
      margin: "0 35px",
      maxHeight: 500,
    },
    leftCategoriesPanel: {
      width: 160,
      backgroundColor: rewardsScreenColors.leftCategoriesPanel,
      color: rewardsScreenColors.categoryItemFont,
      maxHeight: "100%",
      overflow: "auto",
    },
    rightDetailsPanel: {
      display: "flex",
      flexDirection: "column",
      padding: "20px 50px",
      width: "100%",
    },
    categoryListItem: {
      display: "flex",
      padding: 16,
      gap: 18,
      borderBottom: `thin solid ${rewardsScreenColors.categoryItemFont}`,
    },
    earnMoneyBtn: {
      maxWidth: 155,
      backgroundColor: rewardsScreenColors.earnMoreBtnBg,
      fontSize: 14,
    },
    flexColItem: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
    flexRowItem: {
      display: "flex",
      gap: 20,
    },
    cardContainer: {
      display: "flex",
      gap: 20,
      padding: "35px 18px",
      backgroundColor: "white",
      flex: 1,
    },
    offerDetailsContentWrapper: {
      display: " flex",
      flexDirection: " column",
      alignItems: " center",
      padding: " 70px 0",
      gap: " 50px",
      justifyContent: " center",
    },
    cardImg: {
      width: 45,
      height: 45,
    },
    cardTitle: {
      margin: 0,
      color: themeColors.black,
      fontSize: 18,
    },
    cardDes: {
      margin: 0,
      color: rewardsScreenColors.cardTextDesc,
      fontSize: 16,
    },
    accordionWrapper: {
      display: "flex",
      gap: 10,
      flexDirection: "column",
      marginTop: 20,
    },
    brandCardsInSearchWrapper: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
      justifyContent: "center",
      marginTop: 50,
      marginBottom: 50,
    },
    categoryWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
    },
    backArrow: {
      position: 'absolute',
      top: 20,
      left: 8,
    },
    searchWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    searchIcon: {
      position: "absolute",
      left: 12,
      width: 32,
      height: 32,
      color: searchScreenColors.inputFontPlaceholderColor,
    },
    search: {
      height: "40px",
      fontSize: "16px",
      width: "100%",
      paddingLeft: "60px",
      fontWeight: 100,
      borderRadius: "5px",
      border: 0,
      backgroundColor: searchScreenColors.inputBgColor,
      boxShadow: `0 1px 1px 0 ${searchScreenColors.inputDropShadowColor}`,
    },
  };
};
