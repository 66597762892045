import useGetColors from "../../utils/colors";

export const useGetProgressBarStyles = ({ progressBar }) => {
  const { ProgressBarColors } = useGetColors();

  return {
    wrapper: {
      // width: "100%",
      width: "50%",
      backgroundColor: ProgressBarColors.progressBG,
      height: 8,
      borderRadius: 30,
      position: "relative",
    },
    highlight: {
      position: "absolute",
      width: `${progressBar}%`,
      backgroundColor: ProgressBarColors.progressHighlight,
      height: 8,
      borderRadius: 30,
      top: 0,
      left: 0,
    },
    text: {
      position: "absolute",
      top: 9,
      left: `${progressBar - 1}%`,
      color: ProgressBarColors.progressHighlight,
      fontSize: 12,
    },
  };
};
