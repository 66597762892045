export const BRANDS_DEFAULT_COMPACT_SIZE = 11;
export const OFFER_REDEEMED = 'OFFER_REDEEMED';
export const NEW_OFFER = 'NEW_OFFER';
export const NO_RESULT = 'No results found.';
export const COMING_SOON = "Coming Soon";
export const IDENTITYTYPE = [
    {key: 'Driving Licence', value: 'driving_licence'},
    {key: 'Voter ID', value: 'voter_id'}
]
export const RACE = [
    {key: 'American', value: 'American'},
    {key: 'Indian-American', value: 'Indian-American'},
    {key: 'Indian', value: 'Indian'},
    {key: 'Asian', value: 'Asian'},
    {key: 'Other', value: 'Other'},
]
export const COUNTRY = [
    {key: 'United States', value: 'CO-42A82D09'}
]

export const COUNTRY1 = [
    {name: 'United States', uuid: 'CO-42A82D09'}
]

export const RACE1 = [
    {name: 'American', uuid: 'American'},
    {name: 'Indian-American', uuid: 'Indian-American'},
    {name: 'Indian', uuid: 'Indian'},
    {name: 'Asian', uuid: 'Asian'},
    {name: 'Other', uuid: 'Other'},
]
export const LANGUAGES = [
    {name: 'Telugu', uuid: 'Telugu'},
    {name: 'English', uuid: 'English'},
    {name: 'Spanish', uuid: 'Spanish'},
    {name: 'Chinese', uuid: 'Chinese'},
    {name: 'Tagalog', uuid: 'Tagalog'},
    {name: 'Vietnamese', uuid: 'Vietnamese'},
    {name: 'Arabic', uuid: 'Arabic'},
    {name: 'French', uuid: 'French'},
    {name: 'Korean', uuid: 'Korean'},
    {name: 'Russian', uuid: 'Russian'},
    {name: 'Portuguese', uuid: 'Portuguese'},
    {name: 'Haitian Creole', uuid: 'Haitian Creole'},
    {name: 'Hindi', uuid: 'Hindi'},
    {name: 'German', uuid: 'German'},
    {name: 'Polish', uuid: 'Polish'},
    {name: 'Italian', uuid: 'Italian'},
    {name: 'Urdu', uuid: 'Urdu'},
    {name: 'Persian', uuid: 'Persian'},
    {name: 'Japanese', uuid: 'Japanese'},
    {name: 'Gujarati', uuid: 'Gujarati'},
    {name: 'Bengali', uuid: 'Bengali'},
    {name: 'Tamil', uuid: 'Tamil'},
    {name: 'Punjabi', uuid: 'Punjabi'},
    {name: 'Thais', uuid: 'Thais'},
    {name: 'Serbo-Croatian', uuid: 'Serbo-Croatian'},
    {name: 'Armenian', uuid: 'Armenian'},
    {name: 'Greek', uuid: 'Greek'},
    {name: 'Hmong', uuid: 'Hmong'},
    {name: 'Hebrew', uuid: 'Hebrew'},
    {name: 'Khmer', uuid: 'Khmer'},
    {name: 'Navajo', uuid: 'Navajo'},
    {name: 'Yoruba', uuid: 'Yoruba'},
    {name: 'Twi', uuid: 'Twi'},
    {name: 'Igbo', uuid: 'Igbo'},
    {name: 'Amharic', uuid: 'Amharic'},
    {name: 'Somali', uuid: 'Somali'},
    {name: 'Dutch', uuid: 'Dutch'},
    {name: 'Afrikaans', uuid: 'Afrikaans'},
    {name: 'Yiddish', uuid: 'Yiddish'},
    {name: 'Pennsylvania', uuid: 'Pennsylvania'},
    {name: 'Low German', uuid: 'Low German'},
    {name: 'Ilocano', uuid: 'Ilocano'},
    {name: 'Samoan', uuid: 'Samoan'},
    {name: 'Hawaiian', uuid: 'Hawaiian'},
    {name: 'Nepali', uuid: 'Nepali'},
    {name: 'Marathi', uuid: 'Marathi'},
    {name: 'Ukrainian', uuid: 'Ukrainian'},
    {name: 'Swahili', uuid: 'Swahili'},
    {name: 'Malayalam', uuid: 'Malayalam'},
    {name: 'Kannada', uuid: 'Kannada'},
    {name: 'Others', uuid: 'Others'},

]

// English,Spanish,Chinese,Tagalog,Vietnamese,Arabic,French,Korean,Russian,Portuguese,Haitian Creole,Hindi,German,Polish,Italian,Urdu,Persian,Telugu,Japanese,Gujarati,
// Bengali,Tamil,Punjabi,Thais,Serbo-Croatian,Armenian,Greek,Hmong,Hebrew,Khmer,Navajo,Yoruba, Twi, Igbo,Amharic, Somali,Dutch, Afrikaans, Yiddish, Pennsylvania Dutch, 
// Low German,Ilocano, Samoan, Hawaiian,Nepali, Marathi,Ukrainian,Swahili,Malayalam, Kannada, Others

export const RELIGION = [
    {name: 'Christianity', uuid: 'Christianity'},
    {name: 'Islam', uuid: 'Islam'},
    {name: 'Hinduism', uuid: 'Hinduism'},
    {name: 'Buddhism', uuid: 'Buddhism'},
]

// .Dance
// 2.Writing
// 3.Painting
// 4.Photography
// 5.Acting

export const INTERESTS = [
    {name: 'Dance', uuid: 'Dance'},
    {name: 'Writing', uuid: 'Writing'},
    {name: 'Painting', uuid: 'Painting'},
    {name: 'Photography', uuid: 'Photography'},
]

export const IDENTITYTYPE1 = [
    {name: 'Driving Licence', uuid: 'driving_licence'},
    {name: 'Voter ID', uuid: 'voter_id'}
]