import useGetColors from "../../utils/colors";

export const useGetAvatarStyles = ({ size, rounded, fit, flatStyle }) => {
  const { avatarColors } = useGetColors();

  return {
    wrapper: {
      width: size,
      height: size,
      padding: flatStyle ? 0 : 3,
      borderRadius: rounded ? 500 : 28,
      backgroundColor: avatarColors.wrapperBg,
      position: "relative",
      ...(!flatStyle
        ? {
            boxShadow: `12 12 0 20 ${avatarColors.shadowColor}`,
          }
        : {}),
    },
    dp: {
      width: "100%",
      height: "100%",
      resizeMode: fit,
      borderRadius: rounded ? 500 : 28,
    },
    statusIndicator: {
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "25%",
      height: "25%",
      borderRadius: 500,
      borderWidth: 3,
      borderColor: avatarColors.wrapperBg,
      backgroundColor: avatarColors.statusIndicator,
    },
  };
};
