import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    phone: {
      code: "91",
      value: "",
    },
    primaryEmail: "",
    address: {
      location: "",
      city: "",
      addressLine1: "",
      state: "",
      county: "",
      country: "",
      apartmentNo: "",
      zip: "",
      landmark: "",
    },
    demographics: {
      race: "",
      dob: "",
    },
    isAuthenticated: false,
  });

  const [activeBrand, setActiveBrand] = useState([]);

  useEffect(()=> {
    if(userDetails?.firstName === '') {
      setUserDetails(JSON.parse(localStorage.getItem('user')))
    }
  },[userDetails])

  const value = {
    userDetails,
    setUserDetails,
    activeBrand,
    setActiveBrand
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
