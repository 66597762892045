import React, { useState } from "react";
import { Snackbar as MuiSnackbar, ThemeProvider } from "@mui/material";
import Alert from '@mui/material/Alert';
import { theme } from "./index.styles";

const Snackbar = ({ isOpen, severity, message }) => {
    const [open, setOpen] = useState(isOpen)
  return (
    <ThemeProvider theme={theme}>
      <MuiSnackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Alert severity={severity}>
          {message}
        </Alert>
      </MuiSnackbar>
    </ThemeProvider>
  );
};

Snackbar.defaultProps = {
    isOpen: false,
    severity: "error",
    message: ''
};

export default Snackbar;
