import React, { useEffect, useState } from 'react';
import { usePopAddslist } from "../../hooks/services/usePopupadds";
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from './components/ExampleCarouselImage'; // Adjust the import path

function UncontrolledExample() {
  // State to store the fetched image URLs from the API
  const [images, setImages] = useState([]);
  let userdata = JSON.parse(localStorage.getItem("user"));

  const {Popupaddsdata,setPayloads} =usePopAddslist();
console.log(Popupaddsdata,"Popupaddsdata4546")
  useEffect(() => {
    // Fetch images from the API
    // setPayloads(userdata?.uuid)
    fetch('your-api-endpoint')
      .then(response => response.json())
      .then(data => {
        // Assuming the API response is an array of image URLs
        setImages(data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []);

  return (
    <Carousel>
      {/* Map over the images array to dynamically create Carousel.Item components */}
      {Popupaddsdata?.map((item, index) => (
        <Carousel.Item key={index}>
          {/* Render the image */}
          <img src={item?.image} alt={`Slide ${index + 1}`}  />
          {/* Render the caption */}
          <Carousel.Caption>
            <p>{`Slide ${index + 1} label`}</p>
            <p>{`Description for slide ${index + 1}`}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default UncontrolledExample;
