import useGetColors from "../../utils/colors";

export const useGetSignupScreenStyles = ({ stepNo, error }) => {
  const { themeColors } = useGetColors();

  return {
    stepperWrapper: {
      width: `${50 * stepNo}%`,
      height: 4,
      borderRadius: 18,
      transition: "width 0.2s",
      backgroundColor: error ? themeColors.error : themeColors.accent,
    },
    illustration: {
      marginTop: "auto",
      height: "90%",
      minHeight: 625,
    },
    signUpFormContainer: {
      display: "flex",
      gap: 40,
      maxWidth: "70%",
      margin: "auto",
    },
    signupForm: {
      width: 500,
      display: "flex",
      gap: 25,
      flexDirection: "column",
      alignItems: "center",
    },
    formFieldsWrapper: {
      width: "100%",
    },
    formFieldsGroup: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 16,
    },
    formFieldsGroupmobile: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 16,
    },
    footerBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginRight:"70%",
      marginLeft:"61%",
      marginBottom:"50px"
    },
    footerBtnmobile: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginRight:"66%",
      marginLeft:"61%",
      marginBottom:"50px"
    },
    errorText: {
      fontSize: 16,
      color: themeColors.error,
    },
    formFieldsFlexGroup: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
    formFieldsFlexRowGroup: {
      height: "2rem",
      width: "98%",
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      // justifyContent: "space-between",
    },
    dobLabel: {
      position: 'absolute',
      top: '-0px',
      left: '17px',
      padding: '0 5px',
      background: '#fff',
      zIndex: 999
    },
    relative: {
      position: 'relative'
    },
    id_thumb: {
      width: "200px",
      height: "100%"
    }
  };
};
