/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { useGetProfileScreenStyles } from "./../Privacy/index.styles";
import appLogo from "../../assets/images/svg/appLogo.svg";
// import AppFooter from "../../components/AppFooter";
import Footer1 from "../../components/Footer/Footer";

const Privacy = () => {
    const classes = useGetProfileScreenStyles();
    return (
        <div>
            <div css={classes.privacyContainer}>
                <Link to='/home'>
                    <div css={classes.logo}>
                        <img height={38} src={appLogo} alt="money mails logo" />
                    </div>
                </Link>

                <h3>Welcome to MoneyMails</h3>
                <p>
                    your one-stop shop for all things marketing! Our mission is to empower local entrepreneurs and businesses to reach new heights through effective marketing strategies and cutting-edge technology.
                </p>
                <p>
                    Our platform provides a unique space for business owners to connect with their target audience, promote their products and services, and gain valuable insights from customer feedback. With our user-friendly interface and comprehensive database, you can easily reach and engage with your ideal customer.
                </p>
                <p>
                    At MoneyMails, we believe in the power of data-driven marketing to drive real results. That's why we provide our users with the tools and resources they need to succeed in today's fast-paced marketplace. Whether you're looking to boost brand awareness, drive sales, or expand your customer base, we've got you covered.
                </p>

                <p>
                    MoneyMails provides a platform for local entrepreneurs to showcase their deals, coupons, and business updates to the community. They can post their offers via email in two ways: Paid Mail and Informational Mail. With Paid Mail, they earn points (redeemable for cash) when users click on their emails. Informational Mail allows users to access coupons, deals, and vendor information with a single click.
                </p>
                <p>
                    Our aim is to offer a one-stop platform for local deals on everyday essentials like grocery stores, haircuts, salons, and more. Additionally, we provide business owners with a robust database of users, enabling them to leverage our precise user list in every area, maximizing their reach and impact
                </p>
                <p>
                    We offer local entrepreneurs a dedicated online space and exclusive access to a user list, enabling them to market and promote their business daily. Additionally, we provide valuable customer feedback on their products, empowering them to make informed decisions and drive growth
                </p>

                <p>
                    Join our community today and discover the power of effective marketing for yourself!
                </p>

            </div>
            {/* <AppFooter /> */}
            <Footer1 />
        </div>
    );
};

export default Privacy;
