import useGetColors from "../../utils/colors";

export const useGetRewardsScreenStyles = () => {
  const { themeColors, rewardsScreenColors } = useGetColors();

  return {
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: themeColors.bg,
    },
    swal: {
      minHeight: 1000
    },
    titleBanner: {
      height: 120,
      background: rewardsScreenColors.titleBarBg,
      marginBottom: 18,
      display: "flex",
      alignItems: "center",
      padding: '0 80px',
      justifyContent: "space-between",
      color: themeColors.white,
      "& h1": {
        color: "inherit",
      },
    },
    titlehistory: {
      height: 20,
      background: rewardsScreenColors.titleBarBg,
      marginBottom: 10,
      display: "flex",
      alignItems: "center",
      padding: '0 30px',
      justifyContent: "space-between",
      color: themeColors.white,
      "& h1": {
        color: "inherit",
      },
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
      padding: "0 35px",
      marginBottom: 10,
    },
    header: {
      backgroundColor: rewardsScreenColors.screenBg,
      display: "flex",
      gap: 50,
      padding: "32px 25px",
      alignItems: "center",
    },
    progressWrapper: {
      flex: 1,
    },
    userTypeTitle: {
      marginBottom: 8,
    },
    progressBarTitle: {
      fontSize: 11,
      color: rewardsScreenColors.textColor,
      marginTop: 8,
    },
    balanceDetails: {
      display: "flex",
      flexDirection: "column",
      gap: 3,
    },
    balanceDetailsTextLight: {
      fontSize: 10,
      color: rewardsScreenColors.textColor,
      fontWeight: 400,
      display: "flex",
      gap: 5,
      alignItems: "center",
      "& > span": {
        opacity: 0.34,
      },
    },
    balanceDetailsTextStrong: {
      fontSize: 18,
      color: rewardsScreenColors.textColor,
      fontWeight: 500,
    },
    tandctag: {
      backgroundColor: rewardsScreenColors.tandcTagBg,
      color: rewardsScreenColors.textColor,
      padding: "0 8",
      fontSize: 11,
      border: 0,
      borderRadius: 10,
      cursor: "pointer",
    },
    redeemBtn: {
      maxWidth: 135,
      backgroundColor: rewardsScreenColors.redeemBtnBg,
      fontSize: 10,
      paddingTop: 0,
      paddingBottom: 0,
      height: 30,
      borderRadius: 30,
      marginBottom : 5,
      "&:hover": {
        backgroundColor: rewardsScreenColors.redeemBtnBg,
      },
    },
    noredeemBtn: {
      maxWidth: 135,
      backgroundColor: rewardsScreenColors.screenBg,
      fontSize: 10,
      paddingTop: 0,
      paddingBottom: 0,
      height: 30,
      borderRadius: 30,
      "&:hover": {
        backgroundColor: rewardsScreenColors.screenBg,
      },
    },
    body: {
      backgroundColor: rewardsScreenColors.screenBg,
      display: "flex",
      margin: "0 35px",
      // maxHeight: 500,
    },
    leftCategoriesPanel: {
      width: 160,
      backgroundColor: rewardsScreenColors.leftCategoriesPanel,
      color: rewardsScreenColors.categoryItemFont,
      maxHeight: "100%",
      overflow: "auto",
    },
    rightDetailsPanel: {
      display: "flex",
      flexDirection: "column",
      padding: "20px 50px",
      width: "100%",
    },
    categoryListItem: {
      display: "flex",
      padding: 16,
      gap: 18,
      borderBottom: `thin solid ${rewardsScreenColors.categoryItemFont}`,
      cursor: 'pointer'
    },
    selectedcategoryListItem: {
      display: "flex",
      padding: 16,
      gap: 18,
      borderBottom: `thin solid ${rewardsScreenColors.categoryItemFont}`,
      cursor: 'pointer',
      backgroundColor: '#50AB32'
    },
    earnMoneyBtn: {
      maxWidth: 155,
      backgroundColor: rewardsScreenColors.earnMoreBtnBg,
      fontSize: 14,
    },
    flexColItem: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
    flexRowItem: {
      display: "flex",
      gap: 20,
    },
    cardContainer: {
      display: "flex",
      gap: 20,
      padding: "35px 18px",
      backgroundColor: "white",
      flex: 1,
    },
    cardImg: {
      width: 45,
      height: 45,
    },
    cardTitle: {
      margin: 0,
      color: themeColors.black,
      fontSize: 18,
    },
    cardDes: {
      margin: 0,
      color: rewardsScreenColors.cardTextDesc,
      fontSize: 16,
    },
    accordionWrapper: {
      display: "flex",
      gap: 10,
      flexDirection: "column",
      marginTop: 20,
    },
    catTitle: {
      marginTop: 20,
      fontSize: '20px',
      textAlign: 'center',
    },
    categoryView: {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      justifyContent: "center",
      marginTop: 10,
    }
  };
};
