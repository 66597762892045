/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TextBox from "../../components/TextBox";
import SignInTemplate from "../../templates/SignIn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGetNewPasswordStyles } from "../../pages/NewPassword/index.styles";
import { useLandingPageStyles } from "./index.style";
import { UserContext } from "../../context/UserContext";
import { usePassword } from "../../hooks/services/usePassword";
import { Grid } from "@mui/material";
import appLogo from "../../assets/images/svg/appLogo.svg";
import { Website_url } from "../../connections";


const LandingPage = () => {
    const classes = useGetNewPasswordStyles();
    const classes1 = useLandingPageStyles();
    const navigate = useNavigate();

    // const clearCacheData = () => {
    //     caches.keys().then((names) => {
    //         names.forEach((name) => {
    //             caches.delete(name);
    //         });
    //     });
    //     alert("Complete Cache Cleared");
    // };
    const [mobileView, setMobileView] = useState(false);
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 767
                ? setMobileView(true)
                : setMobileView(false);
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, [])

    const redirectToLogin = () => {
        navigate('/login')
    }
    return (
        <Grid item container style={{ width: "100%", height: "100vh" }}>
            <Grid xs={12} md={6} css={classes1.columnOne}>
                <div css={classes1.columnTwodetail}>

                    <div style={{ width: "50%", maxidth: "300px", marginBottom: "5%" }}><img src={appLogo} alt="money mails logo" /></div>

                    <div>
                        <h6 css={classes1.middleheading}>Login now and start earning money for every email that you view!</h6>
                    </div>

                    <div css={classes1.middleheading1}>Rewards in Every Message
                        Earn While You Learn in Money Mails</div>

                    <div style={{ backgroundColor: "#329D9C", color: "white", }}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="secondary"
                            onClick={() => { redirectToLogin() }}
                            style={{ backgroundColor: "#329D9C", color: "white", }}
                        >
                            <div style={{ backgroundColor: "#329D9C", color: "white", }}>
                                <span>Login and Redeem your Rewards</span>
                                <ArrowForwardIcon />
                            </div>
                        </Button>
                    </div>
                </div>


            </Grid>
            {!mobileView &&
                <Grid xs={12} md={6} css={classes1.columnTwo}>
                    <div css={classes1.columnTwodetail}>
                        <div css={classes1.business}><h1 style={{ color: "#fff" }}>Business Owner</h1></div>
                        <div>
                            <h5 css={classes1.middle}>Join Money Mails, Boost your sales, Get the help you need.</h5>
                            <h5 css={classes1.middle1}> Connect, Collaborate, Conquer Vendor Access Awaits</h5>
                            <h6 css={classes1.middle2}> Swift your Approvals, Contact Admin</h6>
                        </div>

                        <div css={classes1.Loginbutton}>
                            <a href={`${Website_url}/login`} target="_blank">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    style={{ marginTop: "10%" }}
                                // onClick={redirecttotheStroreadmin}
                                >
                                    <div css={classes1.buttonContainer}>
                                        <span>Click here to Login</span>
                                        <ArrowForwardIcon />
                                    </div>
                                </Button>
                            </a>
                        </div>
                    </div>


                </Grid>
            }
             {mobileView &&
                <Grid xs={12} md={6} css={classes1.columnTwo}>
                    <div css={classes1.columnTwodetail}>
                        <div css={classes1.business}><h1 style={{ color: "#fff",marginLeft:'15%' }}>Business Owner</h1></div>
                        <div>
                            <h5 css={classes1.middlemobile}>Join Money Mails, Boost your sales, Get the help you need.</h5>
                            <h5 css={classes1.middle1mobile}> Connect, Collaborate, Conquer Vendor Access Awaits</h5>
                            <h6 css={classes1.middle2mobile}> Swift your Approvals, Contact Admin</h6>
                        </div>

                        <div css={classes1.Loginbutton}>
                            <a href={`${Website_url}/login`} target="_blank">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    style={{ marginTop: "10%" }}
                                // onClick={redirecttotheStroreadmin}
                                >
                                    <div css={classes1.buttonContainer}>
                                        <span>Click here to Login</span>
                                        <ArrowForwardIcon />
                                    </div>
                                </Button>
                            </a>
                        </div>
                    </div>


                </Grid>
            }
        </Grid >
        // <SignInTemplate>
        // <div css={classes.container}>
        //     <h2 css={classes.heading}>Information for business owners</h2>

        //     <Button
        //         type="submit"
        //         variant="contained"
        //         fullWidth
        //         color="secondary"
        //         onClick={redirectToLogin}
        //     >
        //         <div css={classes.buttonContainer}>
        //             <span>Login</span>
        //             <ArrowForwardIcon />
        //         </div>
        //     </Button>
        // </div>
        // </SignInTemplate>
    );
};

export default LandingPage;
