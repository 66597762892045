/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TextBox from "../../components/TextBox";
import SignInTemplate from "../../templates/SignIn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGetNewPasswordStyles } from "./index.styles";
import { UserContext } from "../../context/UserContext";
import { usePassword } from "../../hooks/services/usePassword";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";


const ForgotPassword = () => {
   const size = 88
  const styles = useGetBackButtonStyles({ size })
  const [identityType, setIdentityType] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const { userDetails } = useContext(UserContext);
  const { verifyData, passError, data, setPayload } = usePassword();

  const classes = useGetNewPasswordStyles();
  const navigate = useNavigate();

  const validatePasswords = () => {
    return newPwd === confirmPwd;
  };

  const submitHandler = () => {
    if (newPwd === "" || confirmPwd === "") return;
    if (newPwd !== confirmPwd) return;
    const payload = {
      user_uuid: userDetails?.uuid,
      new_password: newPwd
    };
    setPayload({
      ...payload
    });
  };

  useEffect(() => {
    if (data.status === 200) navigate(-1);
  }, [data])


  return (
    <SignInTemplate>
      <div css={classes.container}>
        <IconButton aria-label="back" size="small" css={styles.backArrowpopup}>
          <ArrowBackIosIcon />Back
        </IconButton>
        <h2 css={classes.heading}>Create New Password</h2>
        <form css={classes.formContainer} onSubmit={(e) => e.preventDefault()}>
          <TextBox
            type="text"
            label={verifyData?.data}
            value={identityType}
            onChange={(_e, val) => setIdentityType(val)}
          />
          <TextBox
            type="password"
            label="New Password"
            value={newPwd}
            onChange={(_e, val) => setNewPwd(val)}
          // validationFn={validatePasswords}
          />
          <TextBox
            type="password"
            label="Confirm Password"
            onChange={(_e, val) => setConfirmPwd(val)}
            value={confirmPwd}
            // validationFn={validatePasswords}
            validationFn={() =>
              newPwd === confirmPwd ||
              "Passwords doesn't match"
            }
          />
        </form>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          onClick={submitHandler}
        >
          <div css={classes.buttonContainer}>
            <span>Submit</span>
            <ArrowForwardIcon />
          </div>
        </Button>
      </div>
    </SignInTemplate>
  );
};

export default ForgotPassword;
