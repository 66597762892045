/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { useGetProfileScreenStyles } from "./index.styles";
import appLogo from "../../assets/images/svg/appLogo.svg";
// import AppFooter from "../../components/AppFooter";
import Footer1 from "../../components/Footer/Footer";

const Privacy = () => {
  const classes = useGetProfileScreenStyles();
  return (
    <div>
      <div css={classes.privacyContainer}>
        <Link to='/home'>
          <div css={classes.logo}>
            <img height={38} src={appLogo} alt="money mails logo" to='/home' />
          </div>
        </Link>
        <h3>PRIVACY POLICY</h3>
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          https://money-mails.com (the “Site”).
        </p>
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to https://money-mails.com and governs data collection and
          usage. We have developed this policy in order for you to understand how
          we collect, use, communicate and make use of personal information. The
          following outlines our privacy policy.
        </p>
        <p>
          Similar to other commercial websites, our website utilizes a standard
          technology called ‘cookies’ (see our cookies policy page for more
          information) and server logs to collect information about how our site
          is used. Information gathered through cookies and server logs may
          include the date and time of visits, the pages viewed, time spent at our
          site, and the websites visited just before and just after our own, as
          well as your IP address.
        </p>
        <h4>INTRODUCTION</h4>
        <p>
          This Policy sets out the conditions under which we may process any
          information that we collect from you, or that you provide to us. It
          covers information that could identify you (“personal information”) and
          information that could not. In the context of the law and this notice,
          “process” means collect, store, transfer, use or otherwise act on
          information.
        </p>
        <p>
          We take seriously the protection of your privacy and confidentiality. We
          understand that all visitors to our website are entitled to know that
          their personal data will not be used for any purpose unintended by them,
          and will not accidentally fall into the hands of a third party.
        </p>
        <p>
        money-mails Inc undertakes to preserve the confidentiality of all
          information you provide to us, and hope that you reciprocate. The law
          requires us to tell you about your rights and our obligations to you in
          regards to the processing and control of your personal data.Combined
          with a handful
        </p>
        <h4>COLLECTION OF YOUR PERSONAL INFORMATION</h4>
        <p>
        money-mails Inc may collect personally identifiable information when you
          purchase any product on https://money-mails.com which might be subject to
          delivery to your designated address provided. We collect information
          such as your: Name, Address, E-mail Address, and Phone Number.
          https://money-mails.com may also collect anonymous demographic information,
          which is not unique to you. money-mails Inc encourages you to review the
          privacy statements of websites you choose to link to from money-mails Inc
          so that you can understand how those websites collect, use and share
          your information. We are not responsible for the privacy statements or
          other content on websites outside of the money-mails Inc website.
        </p>
        <h4>WHAT INFORMATION WE COLLECT AND WHY?</h4>
        <p>
          We may collect information about you directly from you, as well as
          automatically through your use of our Site or Services.
        </p>
        <p>
          <strong>Information We Collect Directly from You:</strong> Certain areas
          and features of our Site and Services require product purchase. For the
          purpose of the purchase, you will provide your name, email address,
          delivery address, payment details, and IP address. This information may
          include identifiable data based upon biometric information including
          age, gender, geolocation, clothes style and size, accessories, body
          type, and time.
        </p>
        <p>
          <strong>We may also collect the following information:</strong> device
          ID; location and language information.
        </p>
        <p>
          <strong>Information We Collect Automatically:</strong> We may
          automatically collect the following information about your use of our
          Site via some software analytics including the length of time you visit
          our Site and your movement through our Site.
        </p>
        <h4>
          WE DO NOT SELL, RENT OR LEASE ITS CUSTOMER LISTS TO THIRD PARTIES.
        </h4>
        <p>
        money-mails Inc may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide these
          services to money-mails Inc, and they are required to maintain the
          confidentiality of your information.
        </p>
        <p>
        money-mails Inc will disclose your personal information, without notice,
          only if required to do so by law or in the good faith belief that such
          action is necessary to (a) conform to the edicts of the law or comply
          with legal process served on money-mails Inc or the site; (b) protect and
          defend the rights or property of money-mails Inc; and, (c) act under
          exigent circumstances to protect the personal safety of users of
          money-mails Inc, or the public.
        </p>
        <h4>
          INFORMATION WE PROCESS BECAUSE WE HAVE A CONTRACTUAL OBLIGATION WITH YOU
        </h4>
        <p>
          When you use money-mails Inc website, you agree to our terms and
          conditions, a contract is formed between you and us. In order to carry
          out our obligations under that contract, we must process the information
          you give us. Some of this information may be personal information.
        </p>

      </div>
      {/* <AppFooter /> */}
      <Footer1 />
    </div>
  );
};

export default Privacy;
