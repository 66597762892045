import React, { useState, useEffect } from 'react';
import WebHome from './WebHome';
import MobilHome from './MobilHome';

const ResponsiveScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const [show, setShow] = useState('');
  const [openShow, setOpenShow] = useState(0);
  useEffect(() => {
    handleResize(); // Check the screen size on initial load
    window.addEventListener('resize', handleResize); // Add resize event listener
    return () => window.removeEventListener('resize', handleResize); // Cleanup the event listener on component unmount
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className="mobileScreen">
          <MobilHome />
        </div>
      ) : (
        <div className="webScreen">
          <WebHome />
        </div>
      )}
    </div>
  );
};

export default ResponsiveScreen;
