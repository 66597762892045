import useGetColors from "../../utils/colors";

export const useGetHomeScreenStyles = () => {
  const { appBarColors, searchScreenColors, themeColors } = useGetColors();
  return {
    pageContainer: {
      "& .scrollingCarousal": {
        marginTop: 16,
        "& [data-arrow='left'], & [data-arrow='right']": {
          position: "absolute",
          left: "0",
          height: "50px",
          width: "50px",
          backgroundColor: "rgb(0 0 0 / 57%)",
          backdropFilter: "blur(6px)",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundImage:
            "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYuNjcgMGwyLjgzIDIuODI5LTkuMzM5IDkuMTc1IDkuMzM5IDkuMTY3LTIuODMgMi44MjktMTIuMTctMTEuOTk2eiIgc3R5bGU9IgogICAgZmlsbDogd2hpdGU7CiI+PC9wYXRoPjwvc3ZnPg==)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          "& button": {
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
            border: 0,
            cursor: "pointer",
          },
        },
        "& [data-arrow='right']": {
          left: "unset",
          right: 0,
          transform: "rotate(180deg)",
        },
        "& div:last-child": {
          gap: 12,
        },
      },
    },
    flex: {
      display: 'flex'
    },
    sideDrawer: {
      flexShrink: 0,
      zIndex: 1250
    },
    offerImg: {
      width: "24%",
      height: 180,
    },
    imageView: {
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      display: "flex",
    },
    brandCard: {
      margin: '5px',
      border: `1px solid ${themeColors?.yash}`,
      borderRadius: '100%'
    },
    brandCardsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      margin: "30px auto",
      maxWidth: "90%",
      justifyContent: "center",
      gap: "15px",
      // background: '#efefef'
    },
    tabsWrapper: {
      margin: "30px auto",
      maxWidth: "90%",
    },
    offerCardsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      justifyContent: "center",
      marginTop: 50,
    },
    drawerContainer: {
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
        backgroundColor: "transparent",
        // backgroundColor: "white",
      },
    },
    drawer: {
      backdropFilter: "blur(20px)",
      // backgroundColor: appBarColors.drawerBg,
      backgroundColor: "white",
      minHeight: "100vh",
      boxShadow: `0 3px 6px 0 ${appBarColors.appBarDropShadow}`,
      zIndex: '1255 !important'
    },
    buttons: {
      cursor: 'pointer',
      borderRadius: '5px',
      background: '#50AB32',
      border: 'none',
      height: '30px',
      color: 'white',
    },

    searchBrandsContainer: {
      width: "80%",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      marginTop: "70px",
      justifyContent: "center",
    },

    brandCardsInSearchWrapper: {
      display: "flex",
      flexWrap: "wrap",
      margin: "30px auto",
      gap: "25px",
    },
    searchWrapper: {
      position: "relative",
      width: "100%",
      marginBottom: "40px",
      display: "flex",
      alignItems: "center",
    },
    searchIcon: {
      position: "absolute",
      left: 12,
      width: 52,
      height: 52,
      color: searchScreenColors.inputFontPlaceholderColor,
    },
    search: {
      height: "80px",
      fontSize: "22px",
      width: "100%",
      paddingLeft: "80px",
      fontWeight: 100,
      borderRadius: "5px",
      border: 0,
      backgroundColor: searchScreenColors.inputBgColor,
      boxShadow: `0 1px 1px 0 ${searchScreenColors.inputDropShadowColor}`,
    },
    categoryWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "10px",
    },
    categoryText: {
      color: searchScreenColors.inputFontColor,
    },
    offerDetailsContentWrapper: {
      display: " flex",
      flexDirection: " column",
      alignItems: " center",
      padding: " 70px 0",
      gap: " 50px",
      justifyContent: "center",
      // height : '500px',
      // minHeight : '100vh'
    },
    locationDetailsContentWrapper: {
      margin: '5px 20px',
      display: " flex",
      flexDirection: " row",
      alignItems: " center",
      padding: " 70px 0",
      gap: " 20px",
      justifyContent: " center",
    },
    timerWrapper: {
      display: " flex",
      alignItems: " center",
      gap: " 30px",
      "& span": {
        fontSize: 62,
      },
    },
    offerImage: {
      width: "60%",
    },
    searchWraper: {
      margin: '1rem 4rem',
      padding: '5px',
      display: 'block',
      width: '100%'
    },
    arrowButton: {
    background: 'none',
    border: 'none',
    fontSize: '2rem',
    cursor: 'pointer',
    userSelect: 'none',
    disabled: {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  offerImage: {
    width: '60%',
    height: '70%',
    margin: '0 20px',
  },
  carouselContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  
  
    subText: {
      // margin: '0 1rem',
      marginTop : "-30px",
      padding: '0 5px',
      fontSize: '20px'
    },
    backArrow: {
      position: 'absolute',
      top: 20,
      left: 8,
      fontSize: 14
    },
    backArrows: {
      // position: 'absolute',
      // top: 7,
      // left: 4,
      fontSize: 14
    },
    logo_img: {
      float: 'left',
      marginBottom: '10px',
      clear: 'both'
    },
    searchLayer: {
      minWidth: '300px',
      width: '20%',
      alignItems: 'left'
    },
    moreBrand: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: themeColors?.yashColor
    },
    moreMail: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: 10,
      backgroundColor: themeColors?.yashColor
    },
    noResult: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    relative: {
      clear: 'both',
      position: 'relative'
    },
    progress: {
      position: "absolute",
    },
    Grid:{
      maxWidth:"100%"
    }
  };
};
