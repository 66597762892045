/** @jsxImportSource @emotion/react */
import { useGetProgressBarStyles } from "./index.styles";
import { LinearProgress } from '@mui/material';

const ProgressBar = ({ progress = 0, progress11 = 1000 }) => {
  const progressBar = progress !== 0 ? Number(progress)/10 : 0;
  const styles = useGetProgressBarStyles({ progressBar });

  const progressValue = progressBar > 100 ? 100 : progressBar ;
  return (
    <>
    <LinearProgress variant="determinate" value={progressValue} color="success" style={{width : '100%'}}/>
    <div style={{display:"flex",justifyContent:"end",alignItems:"flex-end"}}>
    <p>{`${progress11} Points`}</p>
  </div>
  </>
    // <div css={styles.wrapper}>
    //   <div css={styles.highlight} />
    //   <span css={styles.text}>{`$${progress}`}</span>
    // </div>
  );
};

export default ProgressBar;
