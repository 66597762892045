import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiSnackbar: {
      styleOverrides: {
        width: '100%'
    },
  },
},
});
