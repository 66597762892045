/** @jsxImportSource @emotion/react */
import { Button as MuiButton, ThemeProvider } from "@mui/material";
import useGetColors from "../../utils/colors";
import { theme } from "./index.styles";

const Button = ({ children, ...props }) => {
  const { buttonColors } = useGetColors();
  const isTextVariant = props.variant === "text";

  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        {...props}
        btnColors={buttonColors}
        disableFocusRipple={isTextVariant}
        disableRipple={isTextVariant}
      >
        {children}
      </MuiButton>
    </ThemeProvider>
  );
};

export default Button;
