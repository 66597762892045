/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext, useRef, Fragment } from "react";
import { Typography, Drawer } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import AppBar from "../../components/AppBar";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { IconButton } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useGetNavItems } from "../Home/appbarNav";
import { useGetRewardsScreenStyles } from "./index.styles";
import OfferCard from "../../components/OfferCard";
// import AppFooter from "../../components/AppFooter";
import uuid from "react-uuid";
import { useMail } from "../../hooks/services/useMail";
import RunCounter from "../../components/RunCounter";
import Snackbar from "../../components/Snackbar";
import BackButton from "../../components/BackButton";
import { useInterval } from "../../hooks/useInterval";
import { SearchOutlined } from "@mui/icons-material";
import Pagination from '../../components/Pagination';
import useGetColors from "../../utils/colors";
import { useNavigate, useLocation } from "react-router-dom";
import Footer1 from "../../components/Footer/Footer";


const arr = new Array(14).fill("").map((item, idx) => idx);

let PageSize = 20;
const MoreMails = () => {
  const classes = useGetRewardsScreenStyles();

const location = useLocation();
const navigate = useNavigate();

  const { offerCardMultiColors: colors } = useGetColors();
  const { userDetails } = useContext(UserContext);
  const [redeemedOfferIndexes, setRedeemedOfferIndexes] = useState([]);
  const options = ["Mails"];
  const [option, setOption] = useState(options[0]);
  const [searchValue, setSearchValue] = useState("");
  const offerDetailsCardRef = useRef(null);
  const [seconds, setSeconds] = useState(0);
  const { mailList, mailCount, isRedeem, redeemMessage, redeemError, mailHTML, setMailPayload, setUpdateMailFav, setUpdateMailRedeem, setMailURL } = useMail();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContentRoute, setDrawerContentRoute] = useState("search");
  const [offerCard, setOfferCard] = useState({});
  const [selectedOfferIndex, setSelectedOfferIndex] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get('country_uuid');
  const state = searchParams.get('state_uuid');
  const city = searchParams.get('city_uuid');
  const subCat = searchParams.get('sub_category_uuid');
  const county = searchParams.get('county_uuid');
  const brandUuid = searchParams.get('brand_uuid');
  const catUuid = searchParams.get('selCategory');

  const [isAnimate, setIsAnimate] = useState(true);

  const [numPages, setNumPages] = useState(1);

  const isOfferContentDrawerActive = drawerContentRoute === "offerCard";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { start, stop } = useInterval((count) => {
    setSeconds(count);
    if (count === 18) {
      // setShowDrawer(false);
      setSeconds(0);
      setIsAnimate(true);
      // setDrawerContentRoute("");
      // setRedeemedOfferIndexes([...redeemedOfferIndexes, selectedOfferIndex]);
      stop();
    }
  });

  useEffect(() => {
    setMailPayload(`pagenum=${currentPage}&limit=${PageSize}&country_uuid=${country}&state_uuid=${state}&city_uuid=${city}&sub_category_uuid=${subCat}&category_uuid=${catUuid}&brand_uuid=${brandUuid}&user_uuid=${userDetails?.uuid}&county_uuid=${county}&type=filter`)
  },[currentPage]);

  useEffect(() => {
    if (isOfferContentDrawerActive) {
      start();
    }
  }, [isOfferContentDrawerActive]);

  const toggleDrawer = (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      isOfferContentDrawerActive
    ) {
      return;
    }

    setShowDrawer(false);
  };

  const onFavouriteMailToggle = (e, uuid) => {
    e.preventDefault();
    setUpdateMailFav({
      user_uuid: userDetails?.uuid,
      mail_uuid: uuid
    })
  }

  useEffect(() => {
    // if(redeemError === false && offerCard?.type === "paid") {
    //   setShowDrawer(true)
    // }
    if (offerCard?.type === "paid") {
      setShowDrawer(true);
    }

  }, [redeemError, isRedeem])

  const handleNavLinksClick = (route, item) => {
    if (item?.mime_type === 'text/html') {
      const url = item?.image_url?.split('/');
      setMailURL({ html_url: url.slice(-1)[0] })
    }
    setOfferCard(item);
    // setShowDrawer(true);
    // setDrawerContentRoute(route);
    setDrawerContentRoute(route);
    {
      item?.type === "regular" &&

      setUpdateMailRedeem({
        user_uuid: userDetails?.uuid,
        mail_uuid: item?.uuid
      });
            // setShowDrawer(true);
    }

    {
      item?.type === "paid" &&
      setUpdateMailRedeem({
        user_uuid: userDetails?.uuid,
        mail_uuid: item?.uuid
      });
    }
    setIsAnimate(false);
    // setUpdateMailRedeem({
    //   user_uuid: userDetails?.uuid,
    //   mail_uuid: item?.uuid
    // });
  };

  const DrawerOfferCardContent = () => (

    <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>

{console.log((redeemError === false && isAnimate), redeemError === true, offerCard?.is_seen === 'true', "Check Status")}
      {offerCard?.type === "paid" && ((redeemError === false && isAnimate) || (redeemError === true) || offerCard?.is_seen === 'true') && <IconButton aria-label="back" size="small" css={classes.backArrow} onClick={(e) => {setDrawerContentRoute('search'); setShowDrawer(false)}}>
        <ArrowBackIosIcon /> Back
      </IconButton>}
      {offerCard?.type === "regular" && <IconButton aria-label="back" size="small" css={classes.backArrow} onClick={(e) => {setDrawerContentRoute('search') ; setShowDrawer(false)}}>
        <ArrowBackIosIcon /> Back
      </IconButton>}
      <div css={classes.timerWrapper}>
        <div>
          {offerCard?.type === "paid" && redeemError === false && offerCard?.is_seen === "false" && seconds !== 0 && <div className={classes.progress} style={{ position: 'relative' }}>
            <FontAwesomeIcon icon={faSpinner} className={isAnimate ? 'fa-4x' : `fa-pulse fa-spin-reverse fa-4x`} color="#1890ff" />
            <Typography variant="caption" component="div" color="text.secondary" style={{ position: 'absolute', top: '20px', left: '14px' }}>
              {`${18 - (seconds === 1 ? 18 : seconds)}sec`}
            </Typography>
          </div>}
        </div>
      </div>
      <Typography css={classes.subText}>
        {offerCard?.message}
      </Typography>
      {offerCard?.mime_type === 'text/html' && <div style={{ maxWidth: '98%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: mailHTML }} />}
      {offerCard?.mime_type === 'application/pdf' && <Document file={offerCard?.image_url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={numPages} />
      </Document>}
      {offerCard?.mime_type === 'image/png' || offerCard?.mime_type === 'image/jpeg' && <img css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />}
    </div>

    // <div css={classes.offerDetailsContentWrapper} ref={offerDetailsCardRef}>
    //   <IconButton aria-label="back" size="medium" css={classes.backArrow} onClick={(e) => setShowDrawer(false)}>
    //     <ArrowBackIosIcon /> Back
    //   </IconButton>
    //   <div css={classes.timerWrapper}>
    //     <div>
    //       {seconds !== 0 && <div className={classes.progress} style={{ position: 'relative' }}>
    //         <FontAwesomeIcon icon={faSpinner} className={isAnimate ? 'fa-4x' : `fa-pulse fa-spin-reverse fa-4x`} color="#1890ff" />
    //         <Typography variant="caption" component="div" color="text.secondary" style={{ position: 'absolute', top: '20px', left: '14px' }}>
    //           {`${18 - (seconds === 0 ? 18 : seconds)}sec`}
    //         </Typography>
    //       </div>}
    //     </div>
    //   </div>
    //   <Typography css={classes.subText}>
    //     {offerCard?.message}
    //   </Typography>
    //   {offerCard?.mime_type === 'text/html' && <div style={{ maxWidth: '98%', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: mailHTML }} />}
    //   {offerCard?.mime_type === 'image/png' || offerCard?.mime_type === 'image/jpeg' && <img crossorigin="anonymous" css={classes.offerImage} src={offerCard?.image_url} alt="offer details" />}
    // </div>
  );

  const renderDrawerContent = () => {
    switch (drawerContentRoute) {
      case "offerCard":
        return <DrawerOfferCardContent />;
    }
  };

  const onSearchInput = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value)
    if (e.target.value.length > 2) {
      setMailPayload(`user_uuid=${userDetails?.uuid}&search=${e.target.value}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}&category_uuid=${catUuid}&brand_uuid=${brandUuid}`);
    }
    if (e.target.value === '') setMailPayload(`pagenum=${currentPage}&limit=${PageSize}&user_uuid=${userDetails?.uuid}&country_uuid=${userDetails?.address?.country}&state_uuid=${userDetails?.address?.state}&city_uuid=${userDetails?.address?.city}&county_uuid=${userDetails?.address?.county}&category_uuid=${catUuid}&brand_uuid=${brandUuid}`);
  }

  return (
    <div css={classes.pageContainer}>
      <AppBar navItems={useGetNavItems({})} drawerWidth={0}/>
      <div css={classes.titleBanner}>
      <div style={{justifyContent: 'flex-end'}}>
        {/* <BackButton /> */}
        <IconButton aria-label="back" size="medium" css={classes.backButton} onClick={() => navigate('/home',{state : {
     data : location?.state,
     id : brandUuid
  }})}>
        <ArrowBackIosIcon /> Back
      </IconButton>
        </div>
        <h1>Mails List</h1>
        <div></div>
      </div>
      <div css={classes.subWraper}>
        <div css={classes.searchWrapper}>
          <SearchOutlined sx={classes.searchIcon} />
          <input
            css={classes.search}
            placeholder={`Search ${option}`}
            onChange={onSearchInput}
          />
        </div>
      </div>
      <div css={classes.brandCardsInSearchWrapper}>
        {mailList && mailList
          .filter((item, i) => {
            if (!searchValue) {
              return true;
            }
            // return item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase());
            return item?.brand[0]?.company_name?.toLowerCase()?.includes(searchValue?.toLowerCase());
          })
          .map((_item, idx) => {
            if(!_item?.is_active) return<Fragment key={_item?.uuid}></Fragment>;
            let j = idx % 4;
            return (
              <div css={classes.categoryWrapper} key={uuid()}>
                <OfferCard
                  key={uuid()}
                  isRedeemed={_item?.is_seen === 'true' ? true : false}
                  containerStyles={{
                    backgroundColor: colors[j]
                  }}
                  item={_item}
                  // size={200}
                  isFavourite={_item?.is_favourite === 'true' ? true : false}
                  onFavouriteMailToggle={(e) => onFavouriteMailToggle(e, _item?.uuid)}
                  message={_item?.message}
                  onClick={() => {
                    // if (_item?.is_seen === 'true') return;
                    setSelectedOfferIndex(idx);
                    handleNavLinksClick("offerCard", _item);
                    // if (!redeemedOfferIndexes.includes(idx)) {
                    //   setSelectedOfferIndex(idx);
                    //   handleNavLinksClick("offerCard", _item);
                    // }
                  }}
                />
                <span css={classes.categoryText}>{_item?.name}</span>
              </div>
            );
          })}
      
      </div>
      <div css={classes.paginationWraper}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={mailCount}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>
      {/* <AppFooter /> */}
      <Footer1 />
      <Drawer
          anchor="top"
          open={showDrawer}
          onClose={toggleDrawer}
          PaperProps={{
            sx: classes.drawer,
          }}
          sx={[classes.drawerContainer, { zIndex: 1277 }]}
        >
          {renderDrawerContent()}
        </Drawer>
        {isRedeem && <Snackbar message={redeemMessage} isOpen={isRedeem} severity={redeemError ? 'error' : 'success'} />}
    </div>
  );
};

export default MoreMails;
