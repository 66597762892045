import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useGetCounterStyles } from './index.styles'

const CircularProgressWithLabel = (props) => {
  const styles = useGetCounterStyles();
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={styles.progress}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const RunCounter = ({value}) => {
  const [progress, setProgress] = React.useState(100 - (value * 10));

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress <= 1000 ? prevProgress - value : 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}

export default RunCounter;
