/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import useGetColors from "../../utils/colors";
import { theme } from "./index.styles";
import { Button } from '@mui/material';

const UploadImage = ({ label, variant, lists, renderKey, renderValue, onChange, id, ...props}) => {
  const { buttonColors } = useGetColors();
  const [val, setVal] = useState('');

  const handleChange = (event) => {
    setVal(event.target.value);
    onChange(event, event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Button 
        component="label"
        btnColors={buttonColors}
        type="button"
        variant={variant}
        fullWidth
        color="primary">
        {label}
        <input hidden accept="image/*" multiple type="file" onChange={handleChange} />
      </Button>
    </ThemeProvider>
  );
};

export default UploadImage;
