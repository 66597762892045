import useGetColors from "../../utils/colors";


export const useLandingPageStyles = () => {
    const { themeColors } = useGetColors();

    return {

        buttonContainer1: {
            backgroundColor: "#329D9C",
            color: "white",
        },
        middle: {
            fontSize: "17px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "5%"
        },
        middle1: {
            fontSize: "16px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "5%"
        },
        middle2: {
            fontSize: "15px",
            color: "#fff",
            textAlign: "center",
        },
        middlemobile: {
            fontSize: "12px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "5%"
        },
        middle1mobile: {
            fontSize: "13px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "5%"
        },
        middle2mobile: {
            fontSize: "15px",
            color: "#fff",
            textAlign: "center",
        },
        columnTwo: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#329D9C",
            color: "#fff",
            padding: "30px"

        },
        heading: {
            color: "#fff",
            fontSize: "35px",
            fontWeight: "500",
            textAlign: "center"
        },
        // columnTwodetail: {
        //     display: "inline-block"
        // },
        Loginbutton: {
            // paddingTop:"90%"
        },
        middleheading: {
            // height: "70vh",
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "50px"
        },
        buttonContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
        },
        middleheading1: {
            // height: "70vh",
            fontSize: "15px",
            textAlign: "center",
            marginBottom: "50px"
        },
        business: {
            marginTop: "5%",
            marginBottom: "20px",
            color: "#fff"
        },
        columnOne: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "#fff",
            color: "#000",
            padding: "30px"
        },
        // Media Queries
        '@media (max-width: 800px)': {
            columnTwo: {
                padding: "30px", // Adjust padding for smaller screens
            },
            columnOne: {
                padding: "30px", // Adjust padding for smaller screens
            },
            heading: {
                fontSize: "16px", // Adjust font size for smaller screens
            },
            // Add more styles for smaller screens as needed
        },
    }
}
