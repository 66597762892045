/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useGetOfferCardStyles } from "./index.styles";
import { NEW_OFFER } from "../../constants";
import BrandIconCard from "../BrandIconCard";
import NikeSVG from "../../icons/nike";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessQueen, faCrown, faEye } from '@fortawesome/free-solid-svg-icons';
import JewelIconSVG from "../../icons/jewelIcon";
import { FavoriteOutlined } from "@mui/icons-material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { IconButton } from "@mui/material";
import Branding from './../../assets/images/Branding.jpg'

const OfferCard = ({ onClick, size, maxSize, height, maxHeight, mobile, containerStyles, isRedeemed, isLocation, item, message = '', isFavourite = false, onFavouriteMailToggle }) => {
  const classes = useGetOfferCardStyles({
    size,
    maxSize,
    containerStyles,
    height,
    maxHeight,
    mobile
  });
  const [isFav, setIsFav] = useState(isFavourite);
  const flat = item?.subject?.split(' ');

  const onFavClick = async (e) => {
    const favAddRes = await onFavouriteMailToggle(e, !isFav);

    setIsFav(!isFav);
    // isLocation();
    e.stopPropagation();
  };
  // const mailsvideo = (data) => {
  //   console.log(data, "itemitem64565tertry5445454")
  // }

  console.log(item, "itemitemitemitem")

  return (
    <div>
      
      <IconButton css={[classes.favIcon, { overflow: 'hidden', position: 'relative', top: '40px', left: mobile ? '75%' : '170px', zIndex: '1000' }]} onClick={onFavClick} >
        {isFav ? (
          <FavoriteOutlined sx={{ color: "red" }} />
        ) : (
          <FavoriteBorderOutlinedIcon sx={{ color: "red" }} />
        )}
      </IconButton>
      <div css={[classes.container, containerStyles]} onClick={onClick}>
        <div css={classes.wrapper}>
          <div css={classes.cardHeaderWrapper}>
            {item?.type === 'paid' ? <FontAwesomeIcon icon={faCrown} className={`fa-2x fa-regular`} color="#eda213" /> : <FontAwesomeIcon icon={faChessQueen} className={`fa-2x fa-regular`} color="#54ac34" />}
            {/* {!isRedeemed && ( */}
            <span css={classes.cardFooterText}></span>
            {/* )} */}

          </div>
          <div>
            {item?.mime_type === 'image/png' || item?.mime_type === 'image/jpeg' ?
              <img key="1" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />
              : item?.mime_type === 'application/pdf' ?
                <img key="2" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />
                : item?.mime_type === 'application/icon' ?
                  <NikeSVG width="70%" height="70%" />
                  :
                  <img key="3" alt={item?.brand[0]?.logo_url || Branding} src={item?.brand[0]?.logo_url || Branding} width={'auto'} height={30} style={{ width: 'auto', height: '40px' }} />}
          </div>
          <div css={classes.offerTitleWrapper} >
            <span css={classes.offerTitle}>{flat?.[0]} {flat?.[1]}</span>
            <span css={classes.offerSubText}>{flat?.[2]} {flat?.[3]}</span>
          </div>
          <div css={{ marginTop: '-5px' }}>
            {item?.type === 'paid' && isRedeemed ? (
              <div css={classes.redeemTag}>Redeemed</div>
            ) : ''}
            {item?.type === 'paid' && item?.is_seen === "false" &&
              <div style={{ padding: 20 }}>
                Unseen
              </div>}
            {item?.type === "regular" && item.is_seen === "true" ? (
              <div style={{ padding: 20 }}>
                {/* <FontAwesomeIcon icon={faEye} className={`fa-2x fa-regular`} color="#54ac34" width={30} /> */}
                Seen
              </div>
            ) : ''}
            {item?.type === "regular" && item.is_seen === 'false' ? (
              <div style={{ padding: 20 }}>
                {/* <FontAwesomeIcon icon={faEye} className={`fa-2x fa-regular`} color="#54ac34" width={30} /> */}
                Unseen
              </div>
            ) : ''}
          </div>
        </div >
        {message && <div css={classes.cardFooter}>
          {message}
        </div>}
      </div>
    </div>
  );
};

OfferCard.defaultProps = {
  status: NEW_OFFER,
  onClick: void 0,
  offerDetails: {},
};

export default OfferCard;
