export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const validateProfileImageOnUpload = (file, message) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  //   const isLt2M = file.size / 1024 / 1024 < 2;

  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }

  return isJpgOrPng; // && isLt2M;
};

export const ssnFormat = (value) => {
  if (!value) return value;
      const ssn = value.replace(/[^\d]/g, '');
      const ssnLength = ssn.length;
      if (ssnLength < 4) return ssn;
      if (ssnLength < 6) {
        return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
      }
      return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
}

export const zipFormat = (value) => {
  if (!value) return value;
      const ssn = value;
      // const ssnLength = ssn.length;
      // if (ssnLength < 6) return ssn;
      // return `${ssn.slice(0, 5)}-${ssn.slice(5, 9)}`;
}

export const IdProofFormat = (value) => {
  if (!value) return value;
      const ssn = value.replace(/[^\d]/g, '');
      const ssnLength = ssn.length;
      if (ssnLength < 4) return ssn;
      if (ssnLength < 9) {
        return `${ssn.slice(0, 4)}-${ssn.slice(4)}`;
      }
      if (ssnLength < 13) {
        return `${ssn.slice(0, 4)}-${ssn.slice(4, 8)}-${ssn.slice(8, 12)}`;
      }
      return `${ssn.slice(0, 4)}-${ssn.slice(4, 8)}-${ssn.slice(8, 12)}-${ssn.slice(12, 16)}`;
}
