import useGetColors from "../../utils/colors";

export const useGetVerificationStyles = () => {
  const { themeColors } = useGetColors();

  return {
    container: {
      width: "70%",
      maxWidth: 500,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      textAlign: "center",
      gap: 40,
      paddingTop: 70,
    },
    heading: {
      margin: 0,
      color: themeColors.black,
    },
    subText: {
      fontWeight: 300,
      paddingTop: 16,
      margin: 0,
      color: themeColors.black,
      fontSize: 20,
    },
    otpFieldsWrapper: {
      display: "flex",
      gap: 12,
    },
    otpField: {
      width: 30,
      height: 30,
      border: 0,
      outline: `thin solid ${themeColors.accent}`,
      borderRadius: 5,
      textAlign: "center",
      fontSize: 20,
      "&:focus": {
        outline: `2px solid ${themeColors.accent}`,
      },
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
        margin: 0,
      },
    },
    textBtnWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: 300,
      marginTop: 8,
    },
  };
};
