import { useContext } from "react";
import { DeviceContext } from "../context/DeviceContext";

const colors = (isDarkMode) => ({
  white: !isDarkMode ? "#ffffff" : "#FAF9F6",
  black: "#000000",
  whiteRGB: "#f2f2f2",
  yashColor: !isDarkMode ? "#f9f9f9" : "#ffffff",
  yash: '#ccc',
  textPrimary: isDarkMode ? "#BABABA" : "#BABABA",
  textSecondary: isDarkMode ? "#ffffff" : "#ffffff",
  accent: isDarkMode ? "#329D9C" : "#329D9C",
  bgPrimary: isDarkMode ? "#ffffff" : "#ffffff",
  error: isDarkMode ? "#d32f2f" : "#d32f2f",
});

const signInTemplateColors = (isDarkMode) => ({
  templateBackground: isDarkMode ? "#ffffff" : "#ffffff",
  sectionBackground: isDarkMode ? "#329D9C" : "#329D9C",
  illustratorTextColor: isDarkMode ? "#000000" : "#000000",
  dropShadowColor: isDarkMode ? "#00000029" : "#00000029",
  templateRightSectionTextColor: isDarkMode ? "#ffffff" : "#ffffff",
  templateMiddleSectionTextColor: isDarkMode ? "#000000" : "#000000",
});

const textFieldColors = (isDarkMode) => ({
  primaryInactiveColor: isDarkMode ? "#DDDDDD" : "#DDDDDD",
  primaryActiveColor: isDarkMode ? "#FFFFFF" : "#FFFFFF",
  secondaryActiveColor: isDarkMode ? "#3e3e3e" : "#3e3e3e",
  secondaryInactiveColor: isDarkMode ? "#8B8B8B" : "#8B8B8B",
  errorActiveColor: isDarkMode ? "#d32f2f" : "#d32f2f",
  errorInactiveColor: isDarkMode ? "#d32f2f" : "#d32f2f",
  defaultFontColor: isDarkMode ? "#000000" : "#000000",
  successIconColor: isDarkMode ? "#58BE3F" : "#58BE3F",
  passwordTypePrimaryFontColor: isDarkMode ? "#DDDDDD" : "#DDDDDD",
  passwordTypeSecondaryFontColor: isDarkMode ? "#3e3e3e" : "#3e3e3e",
});

const buttonColors = (isDarkMode) => ({
  btnPrimary: isDarkMode ? "#329D9C" : "#329D9C",
  btnPrimaryHover: isDarkMode ? "#7abcbb" : "#7abcbb",
  btnFontPrimary: isDarkMode ? "#ffffff" : "#ffffff",
  btnSecondary: isDarkMode ? "#ffffff" : "#ffffff",
  btnSecondaryHover: isDarkMode ? "#FAF9F6" : "#FAF9F6",
  btnFontSecondary: isDarkMode ? "#329D9C" : "#329D9C",
  btnTextFontPrimary: isDarkMode ? "#000000" : "#000000",
  btnTextFontSecondary: isDarkMode ? "#ffffff" : "#ffffff",
});

const appBarColors = (isDarkMode) => ({
  appBarBG: isDarkMode ? "rgb(213 255 201 / 30%)" : "rgb(213 255 201 / 30%)",
  appBarDropShadow: isDarkMode ? "#00000029" : "#00000029",
  drawerBg: isDarkMode
    ? "rgba(255, 255, 255, 0.28)"
    : "rgba(255, 255, 255, 0.7)",
});

const appFooterColors = (isDarkMode) => ({
  appFooterBG: isDarkMode ? "#00C0F0" : "#00C0F0",
});

const brandCardColors = (isDarkMode) => ({
  selection: isDarkMode ? "lightblue" : "lightblue",
  actionBtnBg: isDarkMode
    ? "rgba(255, 255, 255, 0.4)"
    : "rgba(255, 255, 255, 0.4)",
});

const tabViewColors = (isDarkMode) => ({
  tabsBgColor: isDarkMode ? "#F0FCFF" : "#F0FCFF",
  activeTabColor: isDarkMode ? "#00AEEF" : "#00AEEF",
  activetabsBgColor: isDarkMode ? "red" : "red",
  inActivetabsBgColor: isDarkMode ? "#F0FCFF" : "#F0FCFF",
  inActiveTabColor: isDarkMode ? "#797979" : "#797979",
});

const offerCardColors = (isDarkMode) => ({
  cardBgColor: isDarkMode ? "#ffffff" : "#ffffff",
  cardBorderColor: isDarkMode ? "#E9E9E9" : "#E9E9E9",
  cardBorderHighlightColor: isDarkMode ? "#a4a4a4" : "#a4a4a4",
  cardTitleColor: isDarkMode ? "#3B3C3D" : "#3B3C3D",
  cardSubTextColor: isDarkMode ? "#848484" : "#848484",
  redeemedBG: isDarkMode ? "#d4d4d4" : "#fff",
  redeemedFont: isDarkMode ? "#676767" : "#676767",
});

const offerCardMultiColors = ['#d7ddff', '#d8f5a3', '#fcf17e', '#f58d6b'];

const searchScreenColors = (isDarkMode) => ({
  inputBgColor: isDarkMode ? "#F6F6F6" : "#F6F6F6",
  inputFontPlaceholderColor: isDarkMode ? "#A7A7A7" : "#A7A7A7",
  inputFontColor: isDarkMode ? "#303030" : "#303030",
  inputDropShadowColor: isDarkMode ? "#00000029" : "#00000029",
});

const rewardsScreenColors = (isDarkMode) => ({
  titleBarBg:
    "transparent linear-gradient(227deg, #EEEEEE 0%, #EDEDED 13%, #50AB32 100%) 0% 0% no-repeat padding-box",
  screenBg: isDarkMode ? "#EEEEEE" : "#EEEEEE",
  redeemBtnBg: isDarkMode ? "#00AEEF" : "#00AEEF",
  textColor: isDarkMode ? "#205072" : "#205072",
  earnMoreBtnBg: isDarkMode ? "#50AB32" : "#50AB32",
  leftCategoriesPanel: isDarkMode ? "#1D1D1D" : "#1D1D1D",
  categoryItemFont: isDarkMode ? "#ffffff" : "#ffffff",
  categoryItemSelectedBg: isDarkMode ? "#00AEEF" : "#00AEEF",
  categoryItemsDivider: isDarkMode ? "#ffffff" : "#ffffff",
  tandcTagBg: isDarkMode ? "#F8F486" : "#F8F486",
  cardTextDesc: isDarkMode ? "#777777" : "#777777",
});

const ProgressBarColors = () => ({
  progressBG: "#A1A1A157",
  progressHighlight: "#10C299",
});

const avatarColors = (isDarkMode) => ({
  wrapperBg: !isDarkMode ? "#ffffff" : "#000000",
  statusIndicator: "#56C596",
  shadowColor: "#20507221",
});

const useGetColors = () => {
  const { isDarkMode } = useContext(DeviceContext);
  const themeColors = colors(isDarkMode);

  return {
    themeColors,
    signInTemplateColors: signInTemplateColors(isDarkMode),
    textFieldColors: textFieldColors(isDarkMode),
    buttonColors: buttonColors(isDarkMode),
    appBarColors: appBarColors(isDarkMode),
    brandCardColors: brandCardColors(isDarkMode),
    tabViewColors: tabViewColors(isDarkMode),
    offerCardColors: offerCardColors(isDarkMode),
    searchScreenColors: searchScreenColors(isDarkMode),
    rewardsScreenColors: rewardsScreenColors(isDarkMode),
    ProgressBarColors: ProgressBarColors(),
    avatarColors: avatarColors(isDarkMode),
    appFooterColors: appFooterColors(isDarkMode),
    offerCardMultiColors
  };
};

// const colorsForCategories = [
//   "#EEF8FF",
//   "#EEFFF9",
//   "#FEEEFF",
//   "#FFEEF3",
//   "#FFFDEE",
//   "#FFF4EE",
// ];

export default useGetColors;
