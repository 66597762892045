import useGetColors from "../../utils/colors";

export const useGetAppBarStyles = () => {
  const { appBarColors } = useGetColors();

  return {
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "0 45px",
      backgroundColor: appBarColors.appBarBG,
      backdropFilter: "blur(80px)",
      position: "sticky",
      top: "0",
      alignItems: "center",
      height: "80px",
      boxShadow: `0 3px 6px 0 ${appBarColors.appBarDropShadow}`,
      zIndex: 10,
    },
    logo: {
      cursor: "pointer",
      '@media (max-width: 425px)': {
        width: '68px',
      },
    },
    logom: {
      cursor: "pointer",
      '@media (max-width: 425px)': {
        width: '98px',
      },
    },
    mobilelogo: {
      cursor: "pointer",
      '@media (max-width: 425px)': {
        width: '36%',
      },
    },
    Bingologo: {
      cursor: "pointer",
      paddingLeft : '20px',
      width : '100%'
    },
    nav: {
      marginLeft: "auto",
      display: "flex",
      gap: "1em",
    },
  };
};
