import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useSignuppincode = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payloads, setPayloads] = useState('');
  const [page, setpage] = useState(0);
  const [countryid, setcountryid] = useState('');
  const [cityid, setcityid] = useState('');

//   cityid
  const [countyid, setcountyid] = useState('');

//   countyid
  const [stateid, setstateid] = useState('');

//   stateid
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [stateChange, setStateChange] = useState(false)
  const [count ,setcount ] = useState(10)

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-auth-token", userDetails?.token);
  console.log('Enterrr>>>>>>>>>>1', payloads)
  useEffect(() => {
    console.log('Enterrr>>>>>>>>>>1', payloads)

    if(payloads) {
    setIsLoading(true);
      const params = JSON.stringify(payloads);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(`${baseUrl}/pincode/list?${payloads}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) =>{
          console.log(result,"fasgfdfgdresult")
          setData(result);
          setError(false);
          setcount(result?.count);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      }
  }, [stateChange, payloads, page, limit, search]);

  return {
    pincodedata: data,
    isLoading,
    error,
    setPayloads,
    setcityid,
    setcountryid,
    setcountyid,
    setstateid,
    setPayloads,
    count,
    setStateChange,
    stateChange,
    page,
    limit
  };
};
