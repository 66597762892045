/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TextBox from "../../components/TextBox";
import SignInTemplate from "../../templates/SignIn";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useGetNewPasswordStyles } from "./index.styles";
import { UserContext } from "../../context/UserContext";
import { usePassword } from "../../hooks/services/usePassword";
import moneymailslogo from "../../../src/assets/images/svg/money-mails-logo.f4f6aef6.png";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetBackButtonStyles } from "../../components/BackButton/index.styles";
import Previous from '../../assets/images/previous.png';




const NewPassword = () => {
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const { userDetails } = useContext(UserContext);
  const { passError, data, updateResult, setPayload } = usePassword();

  const classes = useGetNewPasswordStyles();
  const navigate = useNavigate();
  const size = 88
  const styles = useGetBackButtonStyles({ size })

  const validatePasswords = () => {
    return newPwd === confirmPwd;
  };

  const submitHandler = () => {
    if (oldPwd === "" || newPwd === "" || confirmPwd === "") return;
    if (newPwd !== confirmPwd) return;
    const payload = {
      user_uuid: userDetails?.uuid,
      old_password: oldPwd,
      new_password: newPwd
    };
    setPayload({
      ...payload
    });
  };

  useEffect(() => {
    if (data.status)
      setTimeout(() => {
        navigate("/login");
      }, 1500);
  }, [data])
  // const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 767
        ? setMobileView(true)
        : setMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, [])
  const Goback = () => {
    navigate('/home')
  }

  return (
    <SignInTemplate>
      <div css={classes.container}>
        {mobileView &&
          <>
            <div>
              <img src={moneymailslogo} style={{ width: '90%' }} alt="illustration" />
            </div>
          
          </>
        }
        {mobileView &&
          <>
            { <div onClick={Goback} style={{ marginLeft: "-13%", marginBottom: "4%" }}><img style={{ height: "25px", cursor: "pointer" }} src={Previous} alt="" />&nbsp;  Back</div>}
          </>
        }

        <h2 css={classes.heading}>Create New Password66</h2>

        <form css={classes.formContainer} onSubmit={(e) => e.preventDefault()}>
          <TextBox
            type="password"
            label="Old Password"
            value={oldPwd}
            onChange={(_e, val) => setOldPwd(val)}
          />
          <TextBox
            type="password"
            label="New Password"
            value={newPwd}
            onChange={(_e, val) => setNewPwd(val)}
          // validationFn={validatePasswords}
          />
          <TextBox
            type="password"
            label="Confirm Password"
            onChange={(_e, val) => setConfirmPwd(val)}
            value={confirmPwd}
            // validationFn={validatePasswords}
            validationFn={() =>
              newPwd === confirmPwd ||
              "Passwords doesn't match"
            }
          />
        </form>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          onClick={submitHandler}
        >
          <div css={classes.buttonContainer}>
            <span>Submit</span>
            <ArrowForwardIcon />
          </div>
        </Button>
      </div>
    </SignInTemplate>
  );
};

export default NewPassword;
