import { useEffect, useState, useContext } from "react";
import { baseUrl } from "../../connections";
import { UserContext } from "../../context/UserContext";

export const useReward = () => {
  const { userDetails } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setPayload] = useState(null);
  const [stateChange1, setStateChange1] = useState(false)
  const [status1, setstatus1] = useState(false)

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("x-auth-token", userDetails?.token);

  useEffect(() => {

    if(payload) {
    setIsLoading(true);
      const params = JSON.stringify(payload);
      const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(`${baseUrl}/user/point/${payload}`, requestOptions)
        .then(async (response) => {
          const res = await response.text();
          if (response.status !== 200) {
            throw res;
          }
          return JSON.parse(res);
        })
        .then((result) => {
          setData(result?.data);
          setError(false);
          setstatus1(true)
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      }
  }, [payload, stateChange1]);

  return {
    rewards: data,
    isLoading,
    error,
    setPayload,
    setStateChange1,
    stateChange1,
    status1
  };
};
